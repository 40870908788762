import { takeLatest } from "redux-saga/effects";
import {
  GetCPT4Codes,
  GetDestroyedDrugs,
} from "../../StateManagement/Reducers/SettingState";
import { GetCPT4CodesWork, GetDestroyedDrugsWork } from "./SettingSagaWorkers";

export function* GetCPT4CodesSaga() {
  yield takeLatest(GetCPT4Codes.type, GetCPT4CodesWork);
}

export function* GetDestroyedDrugsSaga() {
  yield takeLatest(GetDestroyedDrugs.type, GetDestroyedDrugsWork);
}
