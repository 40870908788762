import React, { memo } from "react";
import { Modal, Button } from "react-bootstrap";

const Warning = (props) => {
  const launchWebsite = () => {
    window.open("https://vozohealth.com/pricing", "_blank");
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="upgrade-req-modal popup_centre"
      style={props.bgchange ? { backgroundColor: "rgba(0,0,0,.25)" } : {}}
    >
      <Modal.Header closeButton>
        <Modal.Title>Upgrade Required</Modal.Title>
      </Modal.Header>

      <Modal.Body className="upg-req-margin">
        <div className="upg-req-one">
          <div className="req-1-line1">{props.title}</div>
          <div className="req-1-line2">{props.description}</div>
          <div className="req-1-line3">{props.boxContent}</div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="text-right w-100">
          <Button
            className="upg-req-modal"
            variant="light"
            onClick={props.onHide}
          >
            Cancel
          </Button>
          <Button
            className="upg-req-btn"
            variant="primary"
            onClick={launchWebsite}
          >
            Upgrade
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(Warning);
