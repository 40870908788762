import { call, put } from "redux-saga/effects";
import {
  SetPatientSearchData,
  SetUserSearchData,
  SetPatientSearchNoResult,
  SetUserSearchNoResult,
  setBillingFacility,
} from "../../StateManagement/Reducers/ScheduleState";
import {
  PatientSearchDataApi,
  UserSearchDataApi,
  BillingFacilityApi,
} from "../Apis/ScheduleApi";
import { Decrypt_Value, Encrypt_Value } from "../EncryptDecrypt";

export function* PatientSearchData({ payload }) {
  const Data = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(PatientSearchDataApi, Data);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      if (decryptResponse.length < 1) {
        yield put(SetPatientSearchNoResult(""));
      } else {
        yield put(SetPatientSearchNoResult("1"));
      }
      yield put(SetPatientSearchData(decryptResponse));
    }
  } catch (e) {
    yield put(SetPatientSearchData([]));
    console.log(e.message);
  }
}

export function* UserSearchData({ payload }) {
  const Data = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(UserSearchDataApi, Data);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      if (decryptResponse.length < 1) {
        yield put(SetUserSearchNoResult(""));
      } else {
        yield put(SetUserSearchNoResult("1"));
      }
      yield put(SetUserSearchData(decryptResponse));
    }
  } catch (e) {
    yield put(SetUserSearchData([]));
    console.log(e.message);
  }
}

export function* BillingFacilityWorker() {
  try {
    const res = yield call(BillingFacilityApi);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      if (decryptResponse === false) {
        yield put(setBillingFacility(null));
      } else {
        yield put(setBillingFacility(decryptResponse.id));
      }
    }
  } catch (e) {
    yield put(setBillingFacility(null));
    console.log(e.message);
  }
}
