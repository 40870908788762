import { createSlice } from "@reduxjs/toolkit";

export const PreloginSlice = createSlice({
  name: "Prelogin",
  initialState: {
    EmailResp: {},
    otp: "",
    checkData: {
      clinic: "",
      username: "",
      mobile: "",
    },
    verified: false,
    saveLoading: false,
    showSuccess: false,
    verifiedEmail: "",
    facilityName: "",
    verifyUserEmail: "",
    userOtpData: {},
    token: "",
    sendOtp: false,
    resendValue: 0,
    showLoading: false,
    showLoading2: false,
    showAlert: false,
    modalMsg: "",
    status: "",
  },
  reducers: {
    GetEmailRes: (state) => {
      state.showLoading = true;
      return state;
    },
    setEmailRes: (state, action) => {
      state.EmailResp = action.payload;
      state.sendOtp = false;
      state.showLoading = false;

      if (action.payload.status === "Success") {
        state.otp = action.payload.otp;
      }
    },
    showModal: (state, action) => {
      if (action.payload.mode === "Success") {
        state.showAlert = action.payload.alert;
        state.modalMsg = action.payload.message;
        state.status = action.payload.status;
      } else {
        state.showAlert = action.payload.alert;
        state.modalMsg = action.payload.message;
        state.status = action.payload.status;
      }
    },
    getUserOtp: (state) => {
      state.showLoading2 = true;
      return state;
    },
    setUserData: (state, action) => {
      state.userOtpData = action.payload;
      state.otp = action.payload.otp;
      state.showLoading2 = false;
    },
    setVerifyUserEmail: (state, action) => {
      localStorage.setItem("verified_email", action.payload.email);
      localStorage.setItem("transfer_token", action.payload.token);
      state.sendOtp = true;
    },
    setResendValue: (state, action) => {
      state.resendValue = action.payload;
    },
  },
});

export const {
  GetEmailRes,
  setEmailRes,
  getUserOtp,
  setVerifyUserEmail,
  setUserData,
  setResendValue,
  showModal,
} = PreloginSlice.actions;

export default PreloginSlice.reducer;
