import React from "react";
import { useState } from "react";

import CustomCalendar from "./CustomCalendar";

import { DatePickerInput } from "./styles";
import { Div } from "../billing/CardTransactions/styles";
import { useEffect } from "react";
const CustomDatePicker = ({
  value,
  onChange,
  onClick,
  onKeyPress,
  showPicker,
  onKeyDown,
  onResultSelect,
  validDate,
  max,
  min,
  disabledDate,
  width,
  border,
  closePicker,
  placeholder,
  backgroundColour,
  height,
  disableCustomPicker,
}) => {
  const [dt, setDt] = useState(new Date());

  useEffect(() => {
    if (validDate && validDate !== "") {
      setDt(new Date(validDate));
    } else {
      setDt(new Date());
    }
  }, [showPicker, validDate]);

  return (
    <Div id="customisedDatePicker">
      <DatePickerInput
        width={width}
        border={border}
        height={height}
        backgroundColour={backgroundColour}
        id="date"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onClick={onClick}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        autoComplete="off"
        disabled={disableCustomPicker}
      />

      {showPicker && (
        <CustomCalendar
          todayDate={dt}
          handlepicker={closePicker}
          onResultSelect={onResultSelect}
          result={validDate}
          max={max}
          min={min}
          disabledDates={disabledDate}
        />
      )}
    </Div>
  );
};

export default CustomDatePicker;
