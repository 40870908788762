import axiosInstance from "../../axios/axios";
import { Decrypt_Value } from "../EncryptDecrypt";
// var PID = localStorage.getItem("NewPid");

const userID =
  localStorage.getItem("user_id") &&
  Decrypt_Value(localStorage.getItem("user_id"), "vozo");

export const RevisionApi = async (encounter) =>
  await axiosInstance.get(`/vozo/revision_get?encounter=${encounter}`);

export const FacilitySearchDataApi = async (Data) =>
  await axiosInstance.get(`/vozonew/facility/all?search=` + Data);

export const AppointmentSearchDataApi = async (Data) =>
  await axiosInstance.put(`/vozonew/getallappointmentsnotetake?search=` + Data);

export const NoteUpdateApi = async (id) =>
  await axiosInstance.put(`/vozo/appoinment/noteupdate?id=` + id);

export const ProviderListApi = async () =>
  await axiosInstance.get(`/vozo/Providerlist?uid=${userID}`);
