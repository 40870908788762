import { takeLatest } from "redux-saga/effects";
import {
  getPatList,
  GetRevision,
  GetFacilitySearchData,
  GetAppointmentSearchData,
  GetNoteUpdateData,
  GetMsgList,
} from "../../StateManagement/Reducers/PatientState";
import {
  Revision,
  FacilitySearchData,
  AppointmentSearchData,
  NoteUpdateWorker,
  ProviderList,
} from "./PatientSagaWorkers";

export function* RevisionSaga() {
  yield takeLatest(GetRevision.type, Revision);
}

export function* FacilitySearchDataSaga() {
  yield takeLatest(GetFacilitySearchData.type, FacilitySearchData);
}

export function* AppointmentSearchDataSaga() {
  yield takeLatest(GetAppointmentSearchData.type, AppointmentSearchData);
}

export function* NoteUpdateSaga() {
  yield takeLatest(GetNoteUpdateData.type, NoteUpdateWorker);
}

export function* ProviderListSaga() {
  yield takeLatest(GetMsgList.type, ProviderList);
}
