import axiosInstance from "../../axios/axios";

export const PatientSearchDataApi = async (Data) =>
  await axiosInstance.get(`/vozo/patientsearch?search=` + Data);

export const UserSearchDataApi = async (Data) =>
  await axiosInstance.get(`/vozo/usersearch?search=` + Data + "&status=" + 1);

export const BillingFacilityApi = async (Data) =>
  await axiosInstance.get("/vozo/getbillingfacility");
