import { createSlice } from "@reduxjs/toolkit";

export const ScheduleSlice = createSlice({
  name: "Schedule",
  initialState: {
    PatientSearchData: [],
    UserSearchData: [],
    PatientSearchNoResult: "1",
    UserSearchNoResult: "1",
    ScheduleLoading: false,
    BillingFacility: "",

    saveButton: {
      appointment: false,
      billing: false,
      vitals: false,
    },
    btnDis: false,
    SpinnerVal: true,
    repeats_checked: false,
    callFunction: {
      appointment: 1,
      billing: 1,
      vitals: 1,
    },
    changeFacility: false,
    modifyAppointmentData: [],
    modifyAppointment: false,
  },
  reducers: {
    GetPatientSearchData: (Data) => {
      return Data;
    },
    SetPatientSearchData: (state, action) => {
      state.PatientSearchData = action.payload;
      state.ScheduleLoading = false;
    },
    SetPatientSearchNoResult: (state, action) => {
      state.PatientSearchNoResult = action.payload;
      state.PatientSearchData = [];
    },

    GetUserSearchData: (Data) => {
      return Data;
    },
    SetUserSearchData: (state, action) => {
      state.UserSearchData = action.payload;
      state.ScheduleLoading = false;
    },
    SetUserSearchNoResult: (state, action) => {
      state.UserSearchNoResult = action.payload;
      state.UserSearchData = [];
    },
    GetBillingFacility: (Data) => {
      return Data;
    },
    setBillingFacility: (state, action) => {
      state.BillingFacility = action.payload;
    },
    changeAppointmentValues: (state, action) => {
      let data = action.payload;
      if (data.name === "save button") {
        state.saveButton[data.value] = true;
      } else if (data.name === "disable") {
        state.btnDis = data.value;
      } else if (data.name === "spinner") {
        state.SpinnerVal = data.value;
      } else if (data.name === "repeats checked") {
        state.repeats_checked = data.value;
      } else if (data.name === "call function") {
        state.callFunction[data.value] = state.callFunction[data.value] + 1;
      } else if (data.name === "reset") {
        state.callFunction = {
          appointment: 1,
          billing: 1,
          vitals: 1,
        };
        state.saveButton = {
          appointment: false,
          billing: false,
          vitals: false,
        };
      } else if (data.name === "reset call") {
        state.callFunction = {
          appointment: 1,
          billing: 1,
          vitals: 1,
        };
      }
    },
    changeFacilitySelect: (state, action) => {
      state.changeFacility = action.payload;
    },
    openToModify: (state, action) => {
      const { data, value } = action.payload;
      state.modifyAppointment = value;
      state.modifyAppointmentData = data["row"];
    },
  },
});

export const {
  GetPatientSearchData,
  SetPatientSearchData,
  GetUserSearchData,
  SetUserSearchData,
  SetPatientSearchNoResult,
  SetUserSearchNoResult,
  GetBillingFacility,
  setBillingFacility,
  changeAppointmentValues,
  changeFacilitySelect,
  openToModify,
} = ScheduleSlice.actions;

export default ScheduleSlice.reducer;
