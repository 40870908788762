import { takeLatest } from "redux-saga/effects";

import {
  getUserOtp,
  GetEmailRes,
} from "../../StateManagement/Reducers/PreloginState";
import { GetOtpWorker, SendOtpWorker } from "./PreloginWorkers";

export function* GetUserOtpSaga() {
  yield takeLatest(getUserOtp.type, GetOtpWorker);
}

export function* SendUserOtpSaga() {
  yield takeLatest(GetEmailRes.type, SendOtpWorker);
}
