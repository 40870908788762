import styled from "styled-components";

export const font1 = styled.div`
  font-family: "inter", sans-serif;
`;

export const ModalTitle = styled(font1)`
  margin: 20px;
  font-weight: 500;
  font-family: "inter";
  font-weight: 600;
  font-size: 24px;
  line-height: 29.05px;
  color: #000000;
`;

export const Modclosebtn = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: none;
  margin-left: auto;
  padding-bottom: 1px;
  line-height: 1.15px;
  margin-right: 20px;
  padding: ${(p) => p.padding && p.padding};
  z-index: ${(p) => p.zIndex && p.zIndex};
`;

export const ModalHead = styled.div`
  width: 512px;
  height: 71px;
  margin: 0px;
  border-bottom: 1px solid #cbd5e0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Modal = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 50px;
  bottom: 0;
  z-index: 2;
  transition: all ease 10s;
  animation: fade 0.5s linear;
  background-color: rgb(203, 209, 217, 0.5);
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  width: ${(p) => p.width && p.width};
  margin: 0.05rem auto !important;
  @keyframes fade {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.75;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Container = styled(font1)`
  width: 512px;
  height: max-content;
  background-color: #fff;
  margin-right: 10px;
  border-color: #cbd1d9;
  border-radius: 6px;
`;

export const ModalBody = styled.div`
  height: 485px;
  overflow-y: scroll;
`;

export const Key = styled.div`
  font-weight: ${(p) => p.weight && p.weight};
  font-size: ${(p) => p.size && p.size};
  line-height: ${(p) => p.lineheight && p.lineheight};
  font-family: ${(p) => p.family && p.family};
  margin: ${(p) => p.margin && p.margin};
  color: ${(p) => p.color && p.color};
  width: ${(p) => p.width && p.width};
  text-align: left;
  height: max-content;
`;

export const ModalFooter = styled.div`
  width: 512px;
  height: 20px;
  background-color: #ffff;
`;
export const Modal1 = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 50px;
  bottom: 0;
  transition: all ease 10s;
  animation: fade 0.5s linear;
  background-color: rgb(203, 209, 217, 0.5);
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  width: ${(p) => p.width && p.width};
  margin: 0.05rem auto !important;
  @keyframes fade {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.75;
    }
    100% {
      opacity: 1;
    }
  }
`;
