import styled from "styled-components";
import { MdAdd } from "react-icons/md";
import { FiEdit3 } from "react-icons/fi";
import { BsArrowRightShort } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { Modal } from "../../StyledComponents";
import { Modclosebtn } from "../billing/CardTransactions/transactionDetails/style";
import { TableRow, TableHeadCell, TableBodyCell } from "../../StyledComponents";
import {
  ModalBody,
  ModalDialog,
  ModalHeader,
} from "../setting/PatientPortalSettings/StyledComponents";

// Create a <Title> react component that renders an <h1> which is
// centered, palevioletred and sized at 1.5em
export const RadioBtn1 = styled.button`
  background: ${(props) => (props.active ? "#F1F5F8" : " rgba(0, 0, 0, 0.1)")};
  padding: 7px 17px;
  color: ${(props) => (props.active ? "#2C7BE5" : "#718096")};
  border: 1px solid #ebeaed;
  border-right: 0px;
  width: 100%;
  border-radius: 4px;
  text-align: left;
  @media only screen and (min-width: 1100px) and (max-width: 1250px) {
    padding: 7px 17px;
    font-size: 12px;
  }
  @media (max-width: 1099px) {
    padding: 7px 17px;
    font-size: 10px;
  }
`;
export const RadioBtn2 = styled.button`
  background: ${(props) => (props.active ? "#F1F5F8" : " rgba(0, 0, 0, 0.1)")};
  text-align: left;
  pointer-events: ${(props) => (props.disabled ? "none" : "all")};
  padding: 7px 17px;
  color: ${(props) => (props.active ? "#2C7BE5" : "#718096")};
  border: 1px solid #ebeaed;
  border-left: 0px;
  border-radius: 4px;
  width: 100%;
  @media only screen and (min-width: 1100px) and (max-width: 1250px) {
    padding: 7px 17px;
    font-size: 12px;
  }
  @media (max-width: 1099px) {
    padding: 7px 17px;
    font-size: 10px;
  }
`;

export const RadioBtnDiv1 = styled.div`
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
`;
export const RadioBtnDiv2 = styled.div`
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 8px;
  z-index: 1;
  position: relative;
`;

export const AddNewLink = styled.a``;
export const DivforAddForm = styled.div`
  display: flex;
`;

export const FirstTab = styled.div`
  display: ${(props) => (props.active ? "flex" : "none")};
  border-bottom: 1px solid #ebeaed;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  justify-content: space-between;
`;
export const FirstTabText1 = styled.div`
  color: ${(props) => (props.active ? "#2c7be5" : "#718096")};
  border-bottom: ${(props) => (props.active ? "1px solid #2c7be5" : "unset")};

  padding: 5px 0px;

  cursor: pointer;
  @media only screen and (min-width: 1200px) and (max-width: 1450px) {
    padding: 5px 15px;
    font-size: 12px;
  }
  @media (max-width: 1199px) {
    padding: 5px 5px;
    font-size: 12px;
  }
`;

export const SecondTab = styled.div`
  display: ${(props) => (props.active ? "flex" : "none")};
  border-bottom: 1px solid #ebeaed;
  cursor: pointer;

  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  justify-content: space-between;
  @media (max-width: 1410px) {
    font-size: 11px;
  }
`;
export const SecondTabText1 = styled.div`
  color: ${(props) => (props.active ? "#2c7be5" : "#718096")};
  border-bottom: ${(props) => (props.active ? "1px solid #2c7be5" : "unset")};
  padding: 5px 10px;
`;

export const ExtraComponent = styled.div`
  width: 19%;
  margin-left: auto;
  background-color: white;
  border-radius: 8px;
  position: absolute;
  left: 80%;
  // height: calc(100vh - 70px);
  height: 100%;
  z-index: ${(props) =>
    props.listView ||
    props.secondblock ||
    (props.toolstab2 && props.EmptyClipBoard)
      ? "3"
      : "2"};
`;
export const SideBarHeader = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 13px;
  font-weight: 500;
  color: #2d3748;
  font-size: 20px;
  text-align: left;
  @media (max-width: 1594px) {
    font-size: 16px;
  }
`;

export const Tab1Data = styled.div`
  position: unset;
  z-index: 100;
  background-color: white;
  // height: calc(100vh - 219px);
  overflow: auto;
  width: 100%;
  border-bottom-left-radius: 8px;
  padding-top: 10px;
  top: 190px;
  max-height: 350px;
  @media (max-width: 1574px) {
    top: 210px;
  }
`;

export const NewFormAddBtn = styled.button`
  background: #ffffff;
  border-radius: 4px;
  color: #2c7be5;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 0px;
  font-weight: 600;
  font-size: 14px;
  float: right;
  line-height: 20px;
  @media (max-width: 1574px) {
    top: 210px;
  }
  @media only screen and (min-width: 1413px) and (max-width: 1591px) {
    line-height: 5px;
  }
  @media (max-width: 1412px) {
    top: 210px;
    float: unset;
    border: 1px solid;
    font-size: 14px;
    width: 100%;
    margin-top: 10px;
  }
  @media (max-width: 1591px) {
    font-size: 12px;
  }
`;

export const AddIcon = styled(MdAdd)`
  margin-right: 5px;
  font-size: 16px;
  margin-top: -5px;
`;

export const Tab1DataDiv = styled.div`
  padding: 20px;
  padding-right: 30px;
`;
export const Tab1DataContent = styled.div``;

export const Tab1DataContentUl = styled.ul`
  list-style-type: none;
  padding-left: 0px;
  padding-right: 0px;
`;
export const Tab1DataContentLi = styled.li`
  z-index: 1000;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  text-align: left;
  margin-top: ${(props) => (props.first ? "10px" : "0px")};
  &:hover {
    background: #2c7be5b5;
    color: white;
  }
  &:focus {
    background: #2c7be5;
  }
  @media (max-width: 1250px) {
    font-size: 12px;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MainComp = styled.div``;
export const FormSaveBlock = styled.div`
  width: 79%;
  background-color: #ffffff;
  padding: 20px 24px;
  // box-shadow: 0px 1px 8px rgb(20 46 110 / 10%);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
`;

export const FormSaveBlockinside = styled.div`
  width: 100%;
  display: flex;
`;

export const FormSaveContent = styled.div`
  margin-left: auto;
`;
export const FormSaveAs = styled.button`
  background-color: #ffffff;
  border: 1px solid #2c7be5;
  border-radius: 4px;
  font-family: "Inter", sans-serif;
  color: #2c7be5;
  font-size: 14px;
  line-height: 17px;
  padding: 8px 14px;
  margin-right: 20px;
  &:hover {
    background-color: #2c7be5;
    color: #ffffff;
  }
`;
export const FormSave = styled.button`
  cursor: ${(props) =>
    props.disabled ? "not-allowed !important" : "pointer !important"};
  border: 1px solid #2c7be5;
  border-radius: 4px;
  font-family: "Inter", sans-serif;
  color: #ffffff;
  font-size: 14px;
  line-height: 17px;
  padding: 8px 24px;
  background-color: #2c7be5;

  &.save_active:active {
    background-color: rgba(44, 123, 229, 0.75);
  }
  &.save_active:disabled {
    background-color: rgba(44, 123, 229, 0.5);
    cursor: not-allowed;
  }
  &.close_active:active {
    background-color: rgba(44, 123, 229, 0.1) !important;
  }
  &.close_active:disabled {
    background-color: #fff !important;
    cursor: not-allowed;
  }
  .close_active:hover {
    background-color: rgba(244, 246, 249, 0.75) !important;
    border: 1px solid #2c7be5;
    color: #2c7be5 !important;
  }
  .save_active:hover {
    background-color: #005fb2 !important;
  }
`;

export const ButtonBox = styled.button`
  background: #2c7be5;
  width: 100%;
  height: 64px;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-family: "Inter";
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.0025em;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const ArrowIcon = styled(BsArrowRightShort)`
  width: 24px;
  height: 24px;
`;
export const Container = styled.div`
  padding-bottom: 1px;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);
  border-radius: 8px;
  height: calc(100vh + -81.5px);
`;
export const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: ${({ height }) => height && height};
  padding-bottom: ${({ paddingBottom }) => paddingBottom && paddingBottom};
  border-bottom: ${({ bottomM }) =>
    bottomM ? "0px solid #cbd5e0" : "1px solid #cbd5e0"};
  border-top: ${({ bottomT }) => bottomT && "1px solid #cbd5e0"};
  border-top: ${({ child }) => child && "1px solid #cbd5e0"};
  margin-bottom: ${({ bottom }) => (bottom ? bottom : "0px")};
  justify-content: ${({ justify }) => justify && "space-between"};
  margin-top: ${({ marginT }) => (marginT ? marginT : "26px")};
  padding: ${({ padding }) => padding && padding};
  position: ${({ position }) => position && "absolute"};
  bottom: ${(bottom) => bottom && 0};
`;

export const FlexBox = styled.div`
  width: 50%;
  border-left: ${({ border }) => border && "1px solid #cbd5e0"};
  border-bottom: ${({ borderB }) => borderB && "1px solid #cbd5e0"};
`;

export const ButtonContainer = styled.div`
  margin: ${({ margin }) => margin && "0px 20px 0px 20px"};
  margin-left: ${({ left }) => left && "auto"};
`;

export const Select = styled.select`
  padding: 4px 16px;
  background: #ecedef;
  border-radius: 4px;
  border: none;
  height: 32px;
`;

export const Option = styled.option``;
export const Div = styled.div`
  padding-top: ${({ paddingTop }) => paddingTop && paddingTop};
  border-radius: ${({ borderRadius }) => borderRadius && borderRadius};
  border: ${(p) => p.border && p.border};
  display: ${(p) => p.display && p.display};
  text-align: ${(p) => p.textAlign && p.textAlign};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => p.alignItems && p.alignItems};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  cursor: ${(p) => p.cursor && p.cursor};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
  overflow-x: ${(p) => p.overflowX && p.overflowX};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  color: ${(p) => p.color && p.color};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  font-style: ${(p) => p.fontStyle && p.fontStyle};
  width: ${(p) => p.width && p.width};
  padding: ${({ padding }) => padding && padding};
  position: ${({ position }) => position && position};
  float: ${(p) => p.float && p.float};
`;

export const Span = styled.span`
  margin-right: ${(p) => p.marginRight && p.marginRight};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  color: ${(p) => p.color && p.color};
  font-size: ${(p) => p.fontSize && p.fontSize};
`;

export const Button = styled.button`
  margin-left: 20px;
  background-color: ${({ variation }) => (variation ? "#2C7BE5" : "#FFFFFF")};
  border: ${({ variation }) => (variation ? "none" : "1px solid #2C7BE5")};
  color: ${({ variation }) => (variation ? "#FFFFFF" : "#2C7BE5")};
  padding: ${(padding) => padding && padding};
  border-radius: 4px;
  height: 32px;

  &.save_active:active {
    background-color: rgba(44, 123, 229, 0.75);
  }
  &.save_active:disabled {
    background-color: rgba(44, 123, 229, 0.5);
    cursor: not-allowed;
  }
  &.close_active:active {
    background-color: rgba(44, 123, 229, 0.1) !important;
  }
  &.close_active:disabled {
    background-color: #fff !important;
    cursor: not-allowed;
  }
  .close_active:hover {
    background-color: rgba(244, 246, 249, 0.75) !important;
    border: 1px solid #2c7be5;
    color: #2c7be5 !important;
  }
  .save_active:hover {
    background-color: #005fb2 !important;
  }
`;

export const FormHeaderBlock = styled.div`
  width: 79%;
  position: absolute;
  margin-top: 0px;
  z-index: 1001;
`;

export const FormHeader = styled.div`
  width: 100%;
  background: #2c7be5;
  font-weight: 500;
  color: #ffffff;
  font-size: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-top-left-radius: 8px;
  display: ${({ formClose }) => (formClose ? "none" : "block")};
  border-top-right-radius: 8px;
`;
export const EditIcon = styled(FiEdit3)`
  margin-left: 10px;
  cursor: pointer;
`;
export const FormHeaderInputBlock = styled.div`
  width: 100%;
  background: #2c7be5;
  font-weight: 500;
  color: #ffffff;
  font-size: 20px;
  padding: 13px 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-align: initial;

  display: ${({ formOpen }) => (formOpen ? "none" : "flex")};
`;

export const FormHeaderInput = styled.input`
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #dbdbdb;
  width: 90%;
  color: black;
`;

export const FormHeaderInputSaveBtn = styled.button`
  background: #57bf7f;
  border-radius: 4px;
  border: 0px;
  padding: 3px 26px;
  margin-left: 32px;
`;
export const FormHeaderInputCloseBtnIcon = styled(AiOutlineClose)``;
export const FormHeaderInputCloseBtn = styled.button`
  border: 1px solid #dbdbdb;
  color: #718096;
  border-radius: 4px;
  justify-content: center;
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin-left: 32px;
  padding-right: 10px;
`;

export const ProfileBox = styled.div`
  background: #ecedef;
  border-radius: 4px;
  padding: 8px 15px 8px 15px;
  height: 32px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #2d3748;
  cursor: pointer;
  & svg {
    margin-left: 8px;
  }
`;

export const DropdownContent = styled.div`
  position: absolute;
  bottom: 60px;
  left: 0;
  width: max-content;
  background: #ffffff;
  box-shadow: 0px 9px 42px rgba(0, 0, 0, 0.1), 0px 0px 6px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  animation: fadeIn 0.3s;
  display: ${(p) => (p.display === "true" ? "block" : "none")};
  overflow: hidden;

  @keyframes fadeIn {
    0% {
      height: 0;
    }
    100% {
      height: 83px;
    }
  }
`;

export const DropdownList = styled.div`
  padding: 8px 4px 8px 8px;
  color: #414141;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: "Inter";
  font-size: 14px;
  transition-duration: 0.3s;
  &:hover {
    background: #ececec;
  }
  ${(p) => p.red && "color : #EF5F5F"}
`;

export const AddRow = styled.button`
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  color: #718096;
  font-family: "Inter", sans-serif;
  line-height: 17px;
  margin-left: 20px;
  padding: 8px;
  background-color: #ffffff;

  svg {
    color: #718096;
    margin-top: -3px;
    margin-right: 8px;
  }
`;

export const ClipBoardDiv = styled.div`
  padding: 20px;
  padding-bottom: 10px;
  padding-right: 20px;
  position: absolute;
  width: 100%;

  z-index: 2;
  background-color: white;
  top: 185px;
`;

export const PasteAllBtn = styled.button`
  width: 100%;
  background: #f5f5f5;
  border-radius: 4px;
  color: #2e2e2e;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid #ebeaed;
  font-size: 14px;
  font-weight: 500;
`;
export const CopyAllBlock = styled.div`
  border: 1px solid #ebeaed;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 4%);
  border-radius: 4px;
  padding: 8px;
  text-align: initial;
`;
export const CopyAllBtn = styled.button`
  background: #e8e8e8;
  border-radius: 4px;
  border: 0px;
  padding: 4px;
  margin-left: 4px;
  margin-right: 4px;
`;

export const Search = styled.div`
  width: 200px;
  height: 36px;
  background: #fff;
  padding: 10px 77px 9px 6px;
  border-radius: 4px;
  border: 1px solid rgba(46, 46, 46, 0.25);
  float: right;
`;

export const SearchInputText = styled.input.attrs({
  placeholderTextColor: "red",
})`
  background: none;
  border: none;
  box-shadow: none;
  width: ${(p) => p.width && p.width};
  font-size: 16px;
  padding-left: 5px;
  outline-offset: -26px;
  margin-top: -22px;
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  &:focus {
    outline: none;
  }
`;
export const Image = styled.img`
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  margin-right: ${(p) => p.mr && p.mr};
  margin-top: ${(p) => p.mt && p.mt};
  margin-left: ${(p) => p.ml && p.ml};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  object-fit: ${(p) => p.objectFit && p.objectFit};
  max-height: ${(p) => p.MaxHeight && p.MaxHeight};
  max-width: ${(p) => p.MaxWidth && p.MaxWidth};
  margin: ${(p) => p.margin && p.margin};
  cursor: ${(p) => p.cursor && p.cursor};
`;
export const SearchIconSVG = styled.svg`
  z-index: 1;
  width: 18px;
  height: 18px;
  position: absolute;
  top: ${(p) => (p.top ? p.top : "10px")};
  color: ${(p) => p.color && p.color};
  font-size: ${(p) => p.fontSize && p.fontSize};
  left: ${(p) => (p.left ? p.left : "10px")};
`;
export const FaxDropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 44%;
  background-color: #fff;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
  margin-left: 29px;
  margin-top: 3px;
`;

export const FaxOption = styled.div`
  padding: 9px 63px 13px 13px;
  cursor: pointer;
  text-align: left;
  color: #000;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &:hover {
    background-color: #f2f2f2;
  }
`;
export const Text = styled.p`
  background: ${(p) => p.background && p.background};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  color: ${(p) => p.color && p.color};
  font-family: ${(p) => p.fontFamily && p.fontFamily}, sans-serif;
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  width: ${(p) => p.width && p.width};
  text-align: ${(p) => p.textAlign && p.textAlign};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  font-size: ${(p) => p.fontSize && p.fontSize};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  cursor: ${(p) => p.cursor && p.cursor};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  font-style: ${(p) => p.fontStyle && p.fontStyle};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  letter-spacing: ${(p) => p.letterSpacing && p.letterSpacing};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  svg {
    font-size: ${(p) => p.svgfontSize && p.svgfontSize};
    margin-top: ${(p) => p.svgmarginTop && p.svgmarginTop};
    margin-left: ${(p) => p.svgmarginLeft && p.svgmarginLeft};
  }
  word-break: ${(p) => p.wordBreak && p.wordBreak};
  position: ${(p) => p.position && p.position};
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
  bottom: ${(p) => p.bottom && p.bottom};
  right: ${(p) => p.right && p.right};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  overflow: ${(p) => p.overflow && p.overflow};
  display: ${(p) => p.display && p.display};
  vertical-align: ${(p) => p.verticalAlign && p.verticalAlign};
`;
export const Input = styled.input.attrs((props) => ({
  readOnly: props.readOnly && "readonly",
  autoComplete: props.autoComplete && "off",
}))`
  color: ${(p) => p.color && p.color};
  font-style: ${(p) => p.fontStyle && p.fontStyle};
  margin: ${(p) => p.margin && p.margin};
  display: ${(p) => p.display && p.display};
  text-align: ${(p) => p.textAlign && p.textAlign};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  background: ${(p) => p.background && p.background};
  border: ${(p) => p.border && p.border};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  height: ${(p) => p.height && p.height};
  padding: ${(p) => p.padding && p.padding};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  width: ${(p) => p.width && p.width};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  &:focus {
    outline: none;
  }
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  cursor: ${(p) => p.cursor && p.cursor};
`;

export const FaxDiv = styled.div`
  display: ${(p) => p.display && p.display};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  margin: ${(p) => p.margin && p.margin};
  ${(p) => p.padding && `padding: ${p.padding};`}
  ${(p) => p.border && `border: ${p.border};`}
  ${(p) => p.boxShadow && `box-shadow: ${p.boxShadow};`}  
  ${(p) => p.fontSize && `font-size : ${p.fontSize};`}
  ${(p) => p.overflow && `overflow : ${p.overflow};`}
  ${(p) => p.alignItems && `align-items : ${p.alignItems};`}
  ${(p) => p.justifyContent && `justify-content : ${p.justifyContent};`}
  ${(p) => p.textShadow && `text-shadow : ${p.textShadow};`}
  ${(p) => p.borderRadius && `border-radius: ${p.borderRadius};`}
  z-index: ${(p) => p.zIndex && p.zIndex};
  background-color: ${(p) => p.backColor || "white"};
  color: ${(p) => p.color || "black"};
  border: ${(p) => p.border && p.border};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  padding: ${(p) => p.padding && p.padding};
  text-align: ${(p) => p.textAlign && p.textAlign};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  position: ${(p) => p.position && p.position};
  border: ${(p) => p.border && p.border};
  background: ${(p) => p.background && p.background};
  left: ${(p) => p.left && p.left};
  top: ${(p) => p.top && p.top};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  border-right: ${(p) => p.borderRight && p.borderRight};
  align-items: ${(p) => p.alignItems && p.alignItems};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
`;
export const TabLabel = styled.label`
  display: ${(p) => p.display && p.display};
  cursor: ${(p) => p.cursor && p.cursor};
  color: ${(p) => p.color && p.color};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  color: ${(p) => p.color && p.color};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  margin: ${(p) => p.margin && p.margin};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  position: ${(p) => p.position && p.position};
  padding: ${(p) => p.padding && p.padding};
`;

export const TextArea = styled.textarea`
  resize: none;
  height: ${(p) => p.height && p.height};
  padding: ${(p) => p.padding && p.padding};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  color: ${(p) => p.color && p.color};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  text-align: ${(p) => p.align && p.align};
  border: ${(p) => p.border && p.border};
  width: ${(p) => p.width && p.width};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  outline: none;
`;
export const ModalFax = styled(Modal)`
  width: 100%;
  height: 100%;
  display: ${(p) => (p.show ? "flex" : "none")};
`;
export const ModalFooter = styled.div`
  border-top: 1px solid #cbd5e0;
  padding: 24px 20px 26px 28px;
  display: flex;
  align-items: center;
`;
export const Mclosebtn = styled(Modclosebtn)`
  margin-right: ${(p) => p.marginRight && p.marginRight};
  & svg {
    margin-left: -1px;
  }
`;

//saranya

export const TableRow2 = styled(TableRow)`
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  text-align: left;
  background-color: ${(props) => props.background};
  cursor: ${(p) => p.cursor && p.cursor};
  border-bottom: ${(p) => (p.borderbottom ? "1px solid #D8E0F0" : "none")};
  border-bottom: ${(p) => p.border_Bottom && p.border_Bottom};
  height: ${(p) => (p.height ? p.height : "")};

  &:last-child {
    border-bottom: ${(p) => p.lastchild && "none"};
  }

  border: ${({ border }) => border && border};
  & > th,
  & > td {
    color: ${({ color }) => color && color};
    font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
    font-family: ${({ fontFamily }) => fontFamily && fontFamily};
    line-height: ${(p) => p.lineHeight && p.lineHeight};
    font-size: ${({ fontSize }) => fontSize && fontSize};
    padding: ${({ padding }) => padding && padding};
    padding-top: ${(p) => p.paddingTop && p.paddingTop};
    padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
    border-bottom: ${(p) => p.borderBottom && p.borderBottom};
    background-color: ${(p) => p.backgroundColor && p.backgroundColor};
    border-right: ${(p) => p.borderRight && p.borderRight};
  }
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  overflow-x: ${(p) => p.overflowX && p.overflowX};
  &.table_last_child tr:last-child {
    border-bottom: "none !important";
  }
  &:last-child {
    border-bottom: ${(p) => (p.lastchild ? "none" : p.lastchild)};
    border-right: ${(p) => (p.lastchild ? "none" : p.lastchild)};
  }
`;
export const ModalReferal = styled(Modal)`
  width: 100%;
  height: 100%;
  display: ${(p) => (p.show ? "flex" : "none")};
`;

export const ModalFooter1 = styled(ModalFooter)`
  height: ${(p) => (p.height ? p.height : "unset")};
  padding: ${(p) => p.padding && p.padding};
  border-top: ${(p) => p.borderTop && p.borderTop};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
`;
export const ModalBody1 = styled(ModalBody)`
  overflow-x: ${(p) => p.overflowX && p.overflowX};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
  overflow: ${(p) => p.overflow && p.overflow};
`;
export const ModalDialog1 = styled(ModalDialog)`
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
`;
export const ModalHeader1 = styled(ModalHeader)`
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
`;
export const TableHeadCell1 = styled(TableHeadCell)`
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  &.refcont_headtable {
    padding-left: 64px;
    @media (max-width: 1050px) {
      padding-left: 30px;
    }
  }
`;
export const TableBodyCell1 = styled(TableBodyCell)`
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  text-align: ${(p) => p.textAlign && p.textAlign};
  color: ${(p) => p.color && p.color};
  &.refcont_table {
    padding-left: 64px;
    @media (max-width: 1050px) {
      padding-left: 30px;
    }
  }
`;

export const Span1 = styled(Span)`
  margin-right: ${(p) => p.marginRight && p.marginRight};
  font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
  font-family: ${({ fontFamily }) => fontFamily && fontFamily};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  font-size: ${({ fontSize }) => fontSize && fontSize};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  cursor: ${(p) => p.cursor && p.cursor};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  color: ${(p) => p.color && p.color};
`;

export const RedStar = styled.span`
  color: #b00020;
  vertical-align: 1px;
  font-size: 14px;
  font-weight: 400;
  margin: ${(p) => p.margin && p.margin};
  position: absolute;
  left: ${(p) => p.left && p.left};
  top: ${(p) => p.top && p.top};
  bottom: ${(p) => p.bottom && p.bottom};
`;
export const ErrorMessage = styled.p`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  text-align: left;
`;

export const Button1 = styled(Button)`
  margin-right: ${(p) => p.marginRight && p.marginRight};
  font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
  font-family: ${({ fontFamily }) => fontFamily && fontFamily};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  font-size: ${({ fontSize }) => fontSize && fontSize};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  padding: ${(p) => p.padding && p.padding};
  margin: ${(p) => p.margin && p.margin};
  color: ${(p) => p.color && p.color};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  background-color: ${({ background }) => background && background};
  border-radius: ${({ borderRadius }) => borderRadius && borderRadius};
  &:hover {
    background-color: ${({ hoverbackcolor }) =>
      hoverbackcolor && hoverbackcolor};
  }
`;
export const ModalInfo = styled(Modal)`
  width: 100%;
  height: 100%;
  display: ${(p) => (p.show ? "flex" : "none")};
`;

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Tab = styled.button`
  cursor: ${(props) => (props.disabled ? "not-allowed" : " pointer")};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 36px;
  gap: 10px;
  font-size: 14px;
  font-weight: 600;

  &:not(:last-child) {
    border-right: none;
  }
  &:first-child {
    border-radius: 4px 0 0 4px;
  }
  &:last-child {
    border-radius: 0 4px 4px 0;
  }

  & path {
    pointer-events: none;
  }
  ${({ active }) =>
    active ? "background : #2C7BE5;" : "background : #FFFFFF;"}
  border: 1px solid rgba(46, 46, 46, 0.25);
  ${({ active }) => (active ? "color : white;" : "color : #2E2E2E;")}
  ${({ alert }) =>
    alert
      ? `& svg{
      display : block;
      height : 20px;
      width : 20px;
      color : red;      
    }`
      : `& svg{
      display : none;
    }`}
`;

export const Tab1 = styled.button`
  cursor: ${(props) => (props.disabled ? "not-allowed" : " pointer")};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  width: auto;
  height: 40px;
  font-size: 14px;
  font-weight: 600;

  &:not(:last-child) {
    border-right: none;
    border-bottom: none;
  }
  &:first-child {
    border-radius: 4px 0 0 4px;
    border-bottom: none;
  }
  &:last-child {
    border-radius: 0 4px 4px 0;
    border-bottom: none;
  }

  & path {
    pointer-events: none;
  }
  ${({ active }) =>
    active ? "background : #2C7BE5;" : "background : #FFFFFF;"}
  border: 1px solid rgba(46, 46, 46, 0.25);
  ${({ active }) => (active ? "color : white;" : "color : #2E2E2E;")}
  ${({ alert }) =>
    alert
      ? `& svg{
      display : block;
      height : 20px;
      width : 20px;
      color : red;      
    }`
      : `& svg{
      display : none;
    }`}
`;
