import { call, put } from "redux-saga/effects";
import { Decrypt_Value, Encrypt_Value } from "../EncryptDecrypt";

import { GetOtpApi, SendOtpApi } from "../Apis/PreloginApi";
import {
  setEmailRes,
  setUserData,
  setResendValue,
  showModal,
} from "../../StateManagement/Reducers/PreloginState";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export function* GetOtpWorker({ payload }) {
  let encrypted = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(GetOtpApi, encrypted);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozo");
      if (Data !== "blocked_success") {
        if (Data !== "failed") {
          yield put(setUserData(Data));
          yield put(setResendValue(Data.resend));
        } else {
          yield put(setUserData({}));
        }
      } else {
        yield put(setUserData({}));
      }
    }
  } catch (e) {
    yield put(setUserData({}));
    console.log(e.message);
  }
}

export function* SendOtpWorker({ payload }) {
  let encrypted = Encrypt_Value(payload, "vozo");
  // return false;

  try {
    const res = yield call(SendOtpApi, encrypted);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozo");

      if (Data.status !== "Failure") {
        yield put(setEmailRes(Data));
        yield put(setResendValue(Data.resend));
        yield put(
          showModal({
            mode: "Success",
            alert: true,
            message: "Mail sent successfully",
            status: "success",
          })
        );

        yield call(delay, 1500);

        yield put(
          showModal({
            mode: "",
            alert: false,
            message: "",
            status: "",
          })
        );
      } else {
        yield put(showModal(Data.resend));
        yield put(setEmailRes({ status: "", output: "" }));
        yield put(
          showModal({
            mode: "Failure",
            alert: true,
            message: "Failed to send mail",
            status: "fail",
          })
        );

        yield call(delay, 1500);

        yield put(
          showModal({
            mode: "",
            alert: false,
            message: "",
            status: "",
          })
        );
      }
    }
  } catch (e) {
    yield put(setEmailRes({ status: "", output: "" }));
    yield put(
      showModal({
        mode: "Failure",
        alert: true,
        message: "Failed to send mail",
        status: "fail",
      })
    );

    yield call(delay, 1500);

    yield put(
      showModal({
        mode: "",
        alert: false,
        message: "",
        status: "",
      })
    );
    console.log(e.message);
  }
}
