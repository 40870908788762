import React from "react";
import { SubNavItem, ListItem, UL, Div } from "./styles";
import { useLocation } from "react-router-dom";

const ReportNav = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  return (
    <Div width="100%" paddingLeft="0.86em">
      <UL
        textAlign="left"
        width="100%"
        listStyleType="none"
        padding="0"
        margin="0"
        position="relative"
      >
        <ListItem padding="8px" margin="8px 0 0 0">
          <SubNavItem
            to="/report/billingsummary"
            id="rpt_nav-lnk_smry"
            exact
            className={
              (splitLocation[1] === "report" && splitLocation[2] === "nav") ||
              splitLocation[2] === "billingsummary"
                ? "active"
                : ""
            }
          >
            Billing Summary
          </SubNavItem>
        </ListItem>
        <ListItem padding="8px" margin="0">
          <SubNavItem to="/report/daysheet" exact id="rpt_nav-lnk_pymnt">
            Payment Analysis
          </SubNavItem>
        </ListItem>

        <ListItem padding="8px" margin="0">
          <SubNavItem to="/report/appointments" exact id="rpt_nav-lnk_apmt">
            Appointment Report
          </SubNavItem>
        </ListItem>
        <ListItem padding="8px" margin="0">
          <SubNavItem to="/report/reminder" exact>
            Reminder Report
          </SubNavItem>
        </ListItem>
        <ListItem padding="8px" margin="0">
          <SubNavItem to="/report/patientReport" exact>
            Patient Report
          </SubNavItem>
        </ListItem>
        <ListItem padding="8px" margin="0">
          <SubNavItem to="/report/TelehealthReport" exact id="rpt_nav-lnk_tle">
            Telehealth Report
          </SubNavItem>
        </ListItem>

        <ListItem padding="8px" margin="0">
          <SubNavItem to="/report/user" exact id="rpt_nav-lnk_usrRpt">
            User Report
          </SubNavItem>
        </ListItem>
        <ListItem padding="8px" margin="0">
          <SubNavItem to="/report/invoice" exact id="rpt_nav-lnk_invo">
            Invoices
          </SubNavItem>
        </ListItem>
        <ListItem padding="8px" margin="0">
          <SubNavItem to="/report/clinicalreport" exact>
            Clinical Report
          </SubNavItem>
        </ListItem>
      </UL>
    </Div>
  );
};

export default ReportNav;
