import React, { useEffect } from "react";
import { useState } from "react";
import { Div } from "../billing/CardTransactions/styles";
import { useRef } from "react";
import { useMemo } from "react";
import { DayButtons } from "./styles";
const YearBlock = (props) => {
  const ref = useRef([]);

  const [selectedYear, setSelectedYear] = useState(
    props.currentDate.getFullYear()
  );
  const currentDate = new Date();

  const years = useMemo(() => {
    let year = [];
    let a = 1900;
    for (let i = 0; i < 200; i++) {
      year.push(a + i);
    }
    return year;
  }, [selectedYear]);

  useEffect(() => {
    setSelectedYear(props.currentDate.getFullYear());
  }, [props.currentDate]);
  return (
    <>
      <Div
        id="CustomDatePicker"
        style={{
          maxHeight: "240px",
          overflowY: "scroll",
          paddingTop: "10px",
        }}
        width="100%"
      >
        {years.length !== 0 && (
          <Div
            id="CustomDatePicker"
            dis="flex"
            style={{
              flexWrap: "wrap",
              padding: "0px 15px 15px 15px",
              width: "100%",
            }}
          >
            {years.map((year, i) => {
              return (
                <Div
                  dis="flex"
                  key={year}
                  className="year"
                  justifyContent="center"
                  style={{ flex: "0 0 24.78%" }}
                  id={year}
                >
                  <DayButtons
                    id="CustomDatePicker"
                    fontSize="14px"
                    lineHeight="20px"
                    fontWeight="400"
                    ref={(el) => (ref.current[year] = el)}
                    style={
                      selectedYear === year
                        ? {
                            backgroundColor: "#2C7BE5",
                            border: "1px solid #2C7BE5",
                            color: "white",
                            padding: "5px 2px",
                            borderRadius: "20px",
                          }
                        : {
                            backgroundColor: "white",
                            border: "white",
                            padding: "5px 2px",
                            borderRadius: "20px",
                          }
                    }
                    onClick={(e) => {
                      props.setYear(year - currentDate.getFullYear());
                      setSelectedYear(year);
                      props.YearSelected(e);
                    }}
                  >
                    {year}
                  </DayButtons>
                </Div>
              );
            })}
          </Div>
        )}
      </Div>
    </>
  );
};

export default YearBlock;
