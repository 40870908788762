import { createSlice } from "@reduxjs/toolkit";

export const RevisionSlice = createSlice({
  name: "Revision",
  initialState: {
    Revision: [],
    RevisionLoading: false,
    FacilitySearchData: [],
    AppointmentSearchData: [],
    FacilitySearchNoResult: "1",
    AppointmentSearchNoResult: "1",
    previewdownload: "",
    ProvidersList: [],
    GetPreview: [],
    filterpatient: [],
    fornoresults: "1",
    insuranceOptions: [],
    showModal1: false,
    showModal2: false,
    showPreview: false,
    showAdd1: false,
    showAdd2: false,
    modalMesg: "",
    DeleteTempDocumentData: "",
    editId: 0,
    previewId: 0,
    ProviderList: [],
    generalMsg: "",
    disabledSave: false,
    initialLoading: false,
  },
  reducers: {
    GetRevision: (state) => {
      state.RevisionLoading = true;
    },
    SetRevision: (state, action) => {
      state.Revision = action.payload;
      state.RevisionLoading = false;
    },
    GetFacilitySearchData: (Data) => {
      return Data;
    },
    SetFacilitySearchData: (state, action) => {
      state.FacilitySearchData = action.payload;
      state.ScheduleLoading = false;
    },
    SetFacilitySearchNoResult: (state, action) => {
      state.FacilitySearchNoResult = action.payload;
      state.FacilitySearchData = [];
    },
    GetAppointmentSearchData: (Data) => {
      return Data;
    },
    SetAppointmentSearchData: (state, action) => {
      state.AppointmentSearchData = action.payload;
      state.ScheduleLoading = false;
    },
    SetAppointmentTenData(state, action) {
      state.AppointmentSearchData = [
        ...state.AppointmentSearchData,
        ...action.payload,
      ];
    },
    GetNoteUpdateData(state) {
      return state;
    },
    setNoResult: (state, action) => {
      state.fornoresults = action.payload;
    },
    changeDisableSave: (state, action) => {
      state.disabledSave = action.payload;
    },
    GetMsgList: (state) => {
      state.Loading = true;
    },
    SetGetMsgList: (state, action) => {
      state.ProviderList = action.payload[0];
      state.generalMsg = action.payload[1];
      state.Loading = false;
    },
  },
});

export const {
  GetRevision,
  SetRevision,
  GetFacilitySearchData,
  SetFacilitySearchData,
  GetAppointmentSearchData,
  SetAppointmentSearchData,
  SetAppointmentTenData,
  SetFacilitySearchNoResult,
  GetNoteUpdateData,
  GetOutgoingFaxDetails,
  SetOutgoingFaxDetails,
  GetPreviewDownload,
  setPreviewDownload,
  setPreviewDownloadEmpty,
  GetPreviewDownload_2out,
  setPreviewDownload_2out,
  setPreviewDownload_2outEmpty,
  GetFaxProviderdetails,
  SetFaxProviderdetails,
  getProvidersList,
  setProvidersList,
  getFaxMailProviders,
  setFaxMailProviders,
  getPatList,
  setPatList,
  getSeachProvider,
  setNoResult,
  setProviderList,
  setPatientDetails,
  getSendFaxMail,
  setSendFaxMail,
  GetMsgList,
  SetGetMsgList,
  changeDisableSave,
} = RevisionSlice.actions;

export default RevisionSlice.reducer;
