import React, { useState, useEffect, memo, useRef } from "react";
import { Modal, Form } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import PeopleOutline from "@material-ui/icons/PeopleOutline";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import RoomOutlined from "@material-ui/icons/RoomOutlined";
import LocalHospitalOutlined from "@material-ui/icons/LocalHospitalOutlined";
import PersonOutline from "@material-ui/icons/PersonOutline";
import { Checkbox, Select, InputNumber } from "antd";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import { MdOutlineArrowDropUp, MdOutlineArrowDropDown } from "react-icons/md";
import { IoWalletOutline } from "react-icons/io5";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Dropdown as Dropdownant } from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import doctorLogo from "../../assets/images/fontisto_doctor.png";
import BillingFacilityIcon from "../../assets/images/BillingFacilityIcon.png";
import {
  Spinner,
  Button,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import "./Popups.css";
import moment from "moment";
import axiosInstance from "../../axios/axios";
import axios from "axios";
import CustomSuffixIcon from "../popups/CustomSuffixIcon";
import { DatePicker, TimePicker, Radio } from "antd";
import { IoFlagOutline } from "react-icons/io5";
import { Switch } from "antd";

import { Popup } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { Image, SpinnerDiv } from "../../StyledComponents";
import { Div, Span, Imagecalendar } from "../billing/CardTransactions/styles";
import { BsFillCaretDownFill } from "react-icons/bs";
import CustomDatePicker from "../datepicker/CustomDatePicker";
import UpgradeRequired from "../popups/UpgradeRequired";
import calendar_icon_custom from "../../assets/images/appointment_calender.png";

var CryptoJS = require("crypto-js");

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const Encode_Decode_JSON = {
  stringify: function (DecodingVal) {
    var Data_Val = {
      Encode: DecodingVal.ciphertext.toString(CryptoJS.enc.Base64),
    };
    if (DecodingVal.iv) Data_Val.Decentral = DecodingVal.iv.toString();
    if (DecodingVal.salt) Data_Val.EndCode = DecodingVal.salt.toString();
    return JSON.stringify(Data_Val);
  },
  parse: function (StrVal) {
    var Data_Val = JSON.parse(StrVal);
    var DecodingVal = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(Data_Val.Encode),
    });
    if (Data_Val.Decentral)
      DecodingVal.Decentral = CryptoJS.enc.Hex.parse(Data_Val.Decentral);
    if (Data_Val.EndCode)
      DecodingVal.salt = CryptoJS.enc.Hex.parse(Data_Val.EndCode);

    return DecodingVal;
  },
};

const Decrypt_Value = (data, key) => {
  return JSON.parse(
    CryptoJS.AES.decrypt(data, key, {
      format: Encode_Decode_JSON,
    }).toString(CryptoJS.enc.Utf8)
  );
};

const Encrypt_Value = (Val, key) => {
  return CryptoJS.AES.encrypt(JSON.stringify(Val), key, {
    format: Encode_Decode_JSON,
  }).toString();
};

const NewAppointment = (props) => {
  // ---------------------------------------- State and Variables Start--------------------------------------------------- //
  // var FromDt, ToDt;

  // if (Decrypt_Value(localStorage.getItem("CalenView"), "vozo") === "Day") {
  //   FromDt = moment().format("YYYY-MM-DD");
  //   ToDt = moment().format("YYYY-MM-DD");
  // } else if (
  //   Decrypt_Value(localStorage.getItem("CalenView"), "vozo") === "Week"
  // ) {
  //   FromDt = moment().startOf("week").format("YYYY-MM-DD");
  //   ToDt = moment().endOf("week").format("YYYY-MM-DD");
  // } else if (
  //   Decrypt_Value(localStorage.getItem("CalenView"), "vozo") === "Month"
  // ) {
  //   FromDt = moment().startOf("month").format("YYYY-MM-DD");
  //   ToDt = moment().endOf("month").format("YYYY-MM-DD");
  // }
  const { Option } = Select;

  var start = "";
  var end = "";
  const classes = useStyles();

  var currentDate = new Date();
  props.start ? (start = props.start.start) : (start = currentDate);
  props.start
    ? (end = props.start.end)
    : (end = new Date(currentDate.getTime() + 30 * 60000).toString());

  const [swict_check, setswict_check] = useState(false);
  const [swict_check_mnth, setswict_check_mnth] = useState(false);
  const [Week_and_every, setWeek_and_every] = useState(false);
  const [Month_and_every, setMonth_and_every] = useState(false);
  const [Month_and_every_Interval, setMonth_and_every_Interval] = useState("");
  const [Week_Day_Month_Year, setWeek_Day_Month_Year] = useState("");
  const [swict_drop, setswict_drop] = useState(false);
  const [source, setSource] = useState([]);
  const [patientid, setpatientid] = useState("");
  const [sourceroom, setSourceRoom] = useState([]);
  const [roomval, setRoomVal] = useState("");
  const [CalEvent, setCalEvent] = useState("");
  const [profileid, setprofileid] = useState("");
  const [billingid, setbillingid] = useState("");
  const [sourceprovider, setSourceprovider] = useState([]);
  const [providerid, setproviderid] = useState("");
  const [sourceoffice, setSourceoffice] = useState([]);
  const [officeid, setofficeid] = useState("");
  const [sourcestatus, setSourcestatus] = useState([]);
  const [sourcestatustele, setSourcestatustele] = useState([]);
  const [statusid, setstatusid] = useState("");
  // const [alldaydisable, setalldaydisable] = useState(false);
  const [aptdate, setaptdate] = useState("");
  const [dayswise, setdayswise] = useState(false);
  const [apttime, setapttime] = useState("");
  const [aptfromdate, setappfromdate] = useState("");
  const [apttodate, setapptodate] = useState("");
  const [SpinnerVal, setSpinnerVal] = useState(true);
  const [checking1, setchecking1] = useState(false);
  const [telehealth, settelehealth] = useState([]);
  const [teleprofile, setteleprofile] = useState([]);
  const [Appttype, setAppttype] = useState(1);
  const [forteleornor, setforteleornor] = useState(1);
  const [fornoresults, setfornoresults] = useState("1");
  const [status, setStatus] = useState(false);
  // const [allday, setallday] = useState(false);
  const [videochck, setvideochck] = useState(false);
  const [patsrchvalue, setpatsrchvalue] = useState("");
  const [normalappointment, setnormalappointment] = useState(
    localStorage.getItem("normalapppopup") ? true : false
  );
  const [teleappointment, setteleappointment] = useState(false);
  const [weekday, setweekday] = useState("");

  const [repeatselectnew, setrepeatselectnew] = useState("");
  const [repeatselect2new, setrepeatselect2new] = useState("0");
  const [dateselect2new, setdateselect2new] = useState("");

  const [timelimiterrorbox, settimelimiterrorbox] = useState(false);
  const [repeatOptionErr, setRepeatOptioErr] = useState(false);
  const [timelimiterror, settimelimiterror] = useState("none");
  const [patError, setpatError] = useState(false);
  const [profileError, setprofileError] = useState(false);
  const [examroomError, setexamroomError] = useState(false);
  const [billingError, setBillingError] = useState(false);
  const [provError, setprovError] = useState(false);
  const [officeError, setofficeError] = useState(false);
  const [statusError, setstatusError] = useState(false);
  const [untildateError, setuntildateError] = useState(false);
  const [everydayError, seteverydayError] = useState(false);
  const [selectdayError, setselectdayError] = useState(false);
  const [notDisabledSpec, setNotDisabledSpec] = useState("");

  const [walkincheck, setwalkincheck] = useState(false);
  const [patFlag, setPatFlag] = useState([]);
  const [fetchingOptions, setFetchingOptions] = useState(false);
  var accountType = Decrypt_Value(localStorage.getItem("account_type"), "vozo");
  var trial = Decrypt_Value(localStorage.getItem("trial_period"), "vozo");
  const bill_facility_id = useSelector(
    (state) => state.Schedule.BillingFacility
  );
  var teleFlag = Decrypt_Value(localStorage.getItem("telehealth_flag"), "vozo");

  var authorized = Decrypt_Value(localStorage.getItem("authorized"), "vozo");
  var userID = Decrypt_Value(localStorage.getItem("user_id"), "vozo");
  var main_pro = Decrypt_Value(localStorage.getItem("main_pro"), "vozo");
  var owner_id = Decrypt_Value(localStorage.getItem("owner_id"), "vozo");
  var storedNames = Decrypt_Value(localStorage.getItem("setroles"), "vozo");
  var group_id = Decrypt_Value(localStorage.getItem("group_id"), "vozo");
  var startformat = moment(start, "YYYY-MM-DD HH:mm:ss").format(
    "YYYY-MM-DD HH:mm:ss"
  );
  var endformat = moment(end, "YYYY-MM-DD HH:mm:ss").format(
    "YYYY-MM-DD HH:mm:ss"
  );
  var duration = moment(endformat, "YYYY-MM-DD HH:mm:ss").diff(
    moment(startformat, "YYYY-MM-DD HH:mm:ss"),
    "minutes"
  );
  const [billFacId, setBillFacId] = useState("");

  const [editing, setEditing] = useState(duration);
  const [btnDis, setBtnDis] = useState(false);
  const [workDays, setworkDays] = useState(0);
  const [startTime, setStartTime] = useState(9);
  const [endTime, setEndTime] = useState(17);
  const dateFormat = "MM/DD/yyyy";

  const initialRender = useRef(true);

  const [showrepApp, setShowrepApp] = useState(false);
  const [repvalue, setrepValue] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [date, setDate] = useState("");
  const [dyOfMont, setDyOfMont] = useState("");
  const [wkOfMont, setWkOfMont] = useState("");
  const [aftDate, setAftDate] = useState("");
  const [onDate, setOnDate] = useState("");
  const [onDatefetch, setOnDateFetch] = useState("");
  const [occurence, setOccurence] = useState("1");
  const [custChk, setcustChk] = useState("");
  const [tempOpen, setTempOpen] = useState(false);
  const [inTimeErr, setInTimeErr] = useState(false);
  const [wkErr, setWkErr] = useState(false);
  const [repeatselectmonth, setRepeatSelectMonth] = useState("");
  const [custchkerr, setcustchkerr] = useState(false);

  const [validDate, setValidDate] = useState(
    moment(start).format("MM-DD-YYYY")
  );
  const [showpicker, setShowPicker] = useState(false);
  const [validDate1, setValidDate1] = useState(
    moment(start).format("MM-DD-YYYY")
  );
  const [showpicker1, setShowPicker1] = useState(false);
  const [endErrTime, setEndErrTime] = useState("");
  const [startErrTime, setStartErrTime] = useState("");
  const [frtimeSlots, setFromTimeSlots] = useState([]);
  const [totimeSlots, setToTimeSlots] = useState([]);
  const currentTime = moment().format("h:mm a").split(" ");
  const chekDate = moment().format("YYYY-MM-DD");
  const [upgrade, setUpgrade] = useState(false);

  const [Telehealthplatform, setTelehealthplatform] = useState("zoom");

  let GMeetURL = "";
  let firstDot = window.location.hostname.split(".");
  if (firstDot[0] !== "localhost") {
    GMeetURL = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    GMeetURL = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  const [speciality, setSpeciality] = useState("");
  const [specialError, setSpecialError] = useState(false);
  const [allowAppt, setAllowAppt] = useState(0);

  const [lastdate, setLastdate] = useState(false);
  const [lastdateApp, setLastdateApp] = useState("");
  const [validDate3, setValidDate3] = useState(
    moment(start).format("MM-DD-YYYY")
  );
  const [sortSpeciality, setSortSpecility] = useState("");

  const [showpicker3, setShowPicker3] = useState(false);
  const specialityList = [
    "Plastic Surgery",
    "Dermatology",
    "Sleep Medicine",
    "Allergy & Immunology",
    "Concierge Medicine",
    "Oncology",
    "Palliative Care",
    "Endocrinology",
    "Sports Medicine",
    "Hospice",
    "Podiatry",
    "Pediatrics",
    "Pathology",
    "Obstetrics and Gynecology",
    "Hand Surgery",
    "Hematology",
    "Emergency Medicine",
    "Surgery",
    "Cardiology",
    "Vascular Surgery",
    "Pulmonology",
    "Austism",
    "Radiology",
    "ECG Management",
    "Physical Medicine & Rehabilitation",
    "Opthalmology",
    "Geriatric Medicine",
    "Neurology",
    "Infectious Disease",
    "Nephrology",
    "Ambulatory Care & Surgery",
    "Otolaryngology (ENT)",
    "Rheumatology",
    "Dental Health",
    "Chiropractic",
    "Psychiatry",
    "Urology",
    "Wound Care",
    "Orthopedic Surgery",
    "Behaviour Health",
    "Gastroenterology",
    "Anesthesiology",
    "Family Medicine",
    "Behavioral health therapy",
    "Acupuncture",
    "Lactation consulting",
    "Massage therapy",
    "Counseling",
    "Marriage and family therapy",
    "Psychology",
    "Social work",
    "Dietetics or nutrition counseling",
    "Applied behavior analysis",
    "Occupational therapy",
    "Physical therapy",
    "Speech-language pathology",
    "Substance use counseling",
    "Other",
  ];

  const [billFacility, setBillFacility] = useState([]);
  const [billFacilityID, setBillFacilityID] = useState("");
  const [billFacilityError, setBillFacilityError] = useState(false);

  // ---------------------------------------- State and Variables End--------------------------------------------------- //

  // ---------------------------------------- Functions Start----------------------------------------------------------- //

  const handleSaverepApp = () => {
    if (repeatselect2new === "1") {
      setWeek_Day_Month_Year("week");
    } else if (repeatselect2new === "2") {
      setWeek_Day_Month_Year("month");
    } else if (repeatselect2new === "3") {
      setWeek_Day_Month_Year("year");
    } else if (repeatselect2new === "0") {
      setWeek_Day_Month_Year("day");
    }

    if (repeatselectnew === "") {
      seteverydayError(true);
    } else if (
      repeatselect2new === "1" &&
      (weekday === "" || weekday.length == 1)
    ) {
      setselectdayError(true);
    } else if (custChk === "") {
      setcustchkerr(true);
    } else {
      setselectdayError(false);
      setcustchkerr(false);
      setShowrepApp(false);
    }
    setstatusid("");
  };

  const handleCloserepApp = () => {
    setShowrepApp(false);
    setrepValue("Does not repeat ");
    setdateselect2new("");
    setcustChk("");
    setweekday("");
    setswict_check(false);
    setswict_check_mnth(false);
    setWeek_Day_Month_Year("");
    setrepeatselectnew("");
    setrepeatselect2new("");
    setOnDateFetch("");
    setOnDate("");
    setcustChk("");
    // setValidDate1("");
  };

  const handleShowrepApp = () => {
    setShowrepApp(true);
    setLastdate(false);
    setchecking1(true);

    if (Week_Day_Month_Year === "day") {
      setswict_check(false);
      setswict_check_mnth(false);
    } else if (Week_Day_Month_Year === "week") {
      setswict_check(true);
      setswict_check_mnth(false);
    } else if (Week_Day_Month_Year === "month") {
      setswict_check(false);
      setswict_check_mnth(true);
    } else if (Week_Day_Month_Year === "year") {
      setswict_check(false);
      setswict_check_mnth(false);
    } else {
      setrepeatselectnew("1");
      setrepeatselect2new("1");
      setswict_check(true);
      setweekday("");
      setRepeatSelectMonth("");
      setWeek_and_every(true);
    }
    // if (repeatselect2new === "1") {
    //   setswict_check(true);
    // }
    // setWeek_and_every(true);
    // setdayswise(true);
    // if (repeatselectnew) {
    //   setrepeatselectnew(repeatselectnew);
    // } else {
    //   setrepeatselectnew("1");
    // }
    // if (repeatselect2new && repeatselect2new !== "0") {
    //   setrepeatselect2new(repeatselect2new);
    //   if (repeatselect2new !== "1") {
    //     setswict_check(false);
    //   }
    // } else {
    //   setrepeatselect2new("1");
    //   setswict_check(true);
    // }
    // setswict_check_mnth(false);
    // setselectdayError(false);
    // setcustchkerr(false);
  };
  const handleShowuntildate = () => {
    setLastdate(true);
  };
  const handleShowuntildate1 = () => {
    setLastdate(false);
  };
  const setmonthDropdown = (e) => {
    if (e === "2") {
      if (day === "Monday") {
        setweekday(["", 2]);
      } else if (day === "Tuesday") {
        setweekday(["", 3]);
      } else if (day === "Wednesday") {
        setweekday(["", 4]);
      } else if (day === "Thursday") {
        setweekday(["", 5]);
      } else if (day === "Friday") {
        setweekday(["", 6]);
      } else if (day === "Saturday") {
        setweekday(["", 7]);
      } else if (day === "Sunday") {
        setweekday(["", 1]);
      }
      if (wkOfMont == "First") {
        setMonth_and_every_Interval("1");
      } else if (wkOfMont == "Second") {
        setMonth_and_every_Interval("2");
      } else if (wkOfMont == "Third") {
        setMonth_and_every_Interval("3");
      } else if (wkOfMont == "Fourth") {
        setMonth_and_every_Interval("4");
      } else {
        setMonth_and_every_Interval("5");
      }

      setMonth_and_every(true);
    } else if (e === "1") {
      setrepeatselect2new("2");
      setweekday("");
      setMonth_and_every(false);
    }
    setRepeatSelectMonth(e);
  };

  const handlerepSelect = (e) => {
    if (e === "Does not repeat") {
      setchecking1(false);
      setdayswise(false);
      setMonth_and_every(false);
      setrepeatselectnew("");
      setrepeatselect2new("");
      setRepeatSelectMonth("");
      setdateselect2new("");
      setweekday("");
      setcustChk("");
      setCalEvent("");
    } else if (e === "Daily") {
      setCalEvent("Daily");
      setstatusid("");
      setchecking1(true);
      setrepeatselectnew("1");
      setrepeatselect2new("0");
      setdayswise(false);
      setMonth_and_every(false);
    } else if (e.includes("Weekly on")) {
      setCalEvent("Weekly");
      setstatusid("");
      setchecking1(true);
      setdayswise(true);
      setMonth_and_every(false);
      if (day === "Monday") {
        setweekday(["", 2]);
      } else if (day === "Tuesday") {
        setweekday(["", 3]);
      } else if (day === "Wednesday") {
        setweekday(["", 4]);
      } else if (day === "Thursday") {
        setweekday(["", 5]);
      } else if (day === "Friday") {
        setweekday(["", 6]);
      } else if (day === "Saturday") {
        setweekday(["", 7]);
      } else if (day === "Sunday") {
        setweekday(["", 1]);
      }
    } else if (e.includes("Monthly on the")) {
      setCalEvent("Monthly " + wkOfMont + " " + day);
      setstatusid("");
      setchecking1(true);
      if (day === "Monday") {
        setweekday(["", 2]);
      } else if (day === "Tuesday") {
        setweekday(["", 3]);
      } else if (day === "Wednesday") {
        setweekday(["", 4]);
      } else if (day === "Thursday") {
        setweekday(["", 5]);
      } else if (day === "Friday") {
        setweekday(["", 6]);
      } else if (day === "Saturday") {
        setweekday(["", 7]);
      } else if (day === "Sunday") {
        setweekday(["", 1]);
      }
      if (wkOfMont == "First") {
        setMonth_and_every_Interval("1");
      } else if (wkOfMont == "Second") {
        setMonth_and_every_Interval("2");
      } else if (wkOfMont == "Third") {
        setMonth_and_every_Interval("3");
      } else if (wkOfMont == "Fourth") {
        setMonth_and_every_Interval("4");
      } else {
        setMonth_and_every_Interval("5");
      }
      setrepeatselectnew("1");
      setrepeatselect2new("2");
      setMonth_and_every(true);
      setdayswise(false);
    } else if (e.includes("Annually on")) {
      setCalEvent("Annually");
      setstatusid("");
      setchecking1(true);
      setrepeatselectnew("1");
      setrepeatselect2new("3");
      setdayswise(false);
      setMonth_and_every(false);
    } else if (e.includes("Every Weekday")) {
      setCalEvent("WeeklyByDay");
      setstatusid("");
      setchecking1(true);
      setdayswise(true);
      setMonth_and_every(false);
      setweekday(["", 2, 3, 4, 5, 6]);
    }
    setrepValue(e);
  };

  const handlerepDate = (e) => {
    setOnDate(moment(e).format("YYYY-MM-DD"));
    if (e !== null) {
      setOnDateFetch(e._d);
    }

    setdateselect2new("On");
    setcustChk(2);
  };

  const convertHMS = (value) => {
    return Math.floor(value * 60);
  };

  const disabledFromTimes = (time, meridiem, type) => {
    const now = moment();
    const currentTime = moment(`${time} ${meridiem}`, "h:mm A");
    const appointmentDate = moment(aptdate);
    if (appointmentDate.isSame(now, "day")) {
      if (type === 2 && currentTime.isBefore(now)) {
        return true;
      }
    }
    return false;
  };
  const disabledToTime = (time, meridiem, id, type) => {
    if (!inTime) return false;

    const fromTime = moment(inTime, "h:mm A");
    const toTime = moment(`${time} ${meridiem}`, "h:mm A");
    const appointmentDate = moment(aptdate);
    if (fromTime.isAfter(toTime) || fromTime.isSame(toTime)) {
      return true;
    }

    return false;
  };

  const buttonClickActions = async (e) => {
    e.preventDefault();
    if (billFacId === undefined) {
      props.redirect();
      return;
    }
    var statusid_temp;
    if (checking1 === true) {
      statusid_temp = "";
    } else {
      statusid_temp = statusid;
    }
    if (inTime === "") {
      setInTimeErr(true);
      return;
    }
    if (billFacilityID === "" || billFacilityID === null) {
      setBillFacilityError(true);
      return;
    }
    const timenew_alert = moment(outTime, ["h:mm A"]).format("HH:mm");
    const time = timenew_alert.split(":");
    const timeHour = time[0];
    // const a = moment(tempdate).add(15, "minutes").format("h:mma");
    const a = moment(timenew_alert, "hh:mm A").format("hh:mm A");

    const maintime = moment(a, "h:mma");

    var b;
    if (endTime !== "12") {
      b = moment(`0${endTime - 12}:00pm`, "h:mma");
    } else {
      b = moment(`${endTime - 12}:00pm`, "h:mma");
    }

    if (inTimeErr || timelimiterrorbox) {
      return false;
    }

    if (
      Number(startTime) <= Number(timeHour) &&
      Number(timeHour) <= Number(endTime)
    ) {
      settimelimiterrorbox(false);
      settimelimiterror("none");
    } else {
      settimelimiterrorbox(true);
      settimelimiterror("block");
      return false;
    }

    let splitTime = apttime.split(":");
    let splitMin = splitTime[1].split(" ");

    if (splitMin[1] === "PM" && Number(splitTime[0]) !== Number(12)) {
      let totTime = +splitTime[0] + +12;
      if (totTime >= outTime) {
        settimelimiterrorbox(true);
        return false;
      } else if (totTime < startTime) {
        settimelimiterrorbox(true);
        return false;
      }
    }

    let outSplitTime = outTime.split(":");
    let outSplitMin = outSplitTime[1].split("");

    if (outSplitMin[1] === "PM" && Number(outSplitTime[0]) !== Number(12)) {
      let totTime = +outSplitTime[0] + +12;
      if (totTime >= outTime) {
        settimelimiterrorbox(true);
        return false;
      }
    }

    var timenew = aptdate + " " + apttime;

    var timedetnew = moment(timenew, "MM-DD-YYYY HH:mm A").format(
      "YYYY-MM-DD HH:mm a"
    );
    var durationnew = editing;
    var appttypenew = Appttype;
    // var alldaynew = alldaydisable;
    var walkinnew = walkincheck;
    var select1, select2, untildate, fromdate, todate, days_wise, repeats;
    if (weekday != null) {
      if (weekday || swict_check) {
        if (swict_check && (weekday === "" || weekday.length == 1)) {
          setWkErr(true);
          return false;
        } else {
          setWkErr(false);
          repeats = weekday;
        }
      } else {
        repeats = "";
      }
    } else {
      repeats = "";
    }

    if (repeatselectnew) {
      select1 = repeatselectnew;
    } else {
      select1 = 0;
    }

    if (repeatselect2new) {
      select2 = repeatselect2new;
    } else {
      select2 = "";
    }

    var repeat_type;
    var recurranceCount;
    if (dateselect2new) {
      if (dateselect2new === "Never") {
        repeat_type = "Never";
        recurranceCount = "";
      } else if (dateselect2new === "On") {
        untildate = onDate;
        repeat_type = "On";
        recurranceCount = "";
      } else if (dateselect2new === "After") {
        if (repeatselect2new === "0") {
          let dt = new Date(aptdate);
          dt.setDate(dt.getDate() + repeatselectnew * occurence);
          dt = dt.toLocaleDateString();
          untildate = moment(new Date(dt)).format("YYYY-MM-DD");
        } else if (repeatselect2new === "1") {
          let dt = new Date(aptdate);
          dt.setDate(dt.getDate() + repeatselectnew * (occurence * 7));
          dt = dt.toLocaleDateString();
          let finalDate = moment(new Date(dt)).format("YYYY-MM-DD");
          untildate = moment(finalDate, "YYYY-MM-DD");
          untildate.subtract(1, "days");
        } else if (repeatselect2new === "2") {
          let dt = new Date(aptdate);
          dt.setMonth(dt.getMonth() + repeatselectnew * occurence);
          dt = dt.toLocaleDateString();
          let finalDate = moment(new Date(dt)).format("YYYY-MM-DD");
          untildate = moment(finalDate, "YYYY-MM-DD");
          untildate.subtract(1, "days");
        } else if (repeatselect2new === "3") {
          let dt = new Date(aptdate);
          dt.setMonth(dt.getMonth() + repeatselectnew * (occurence * 12));
          dt = dt.toLocaleDateString();
          untildate = moment(new Date(dt)).format("YYYY-MM-DD");
        } else if (repeatselect2new === "4") {
          let dt = new Date(aptdate);
          dt.setDate(dt.getDate() + repeatselectnew * occurence);
          dt = dt.toLocaleDateString();
          untildate = moment(new Date(dt)).format("YYYY-MM-DD");
        }
        repeat_type = "After";
        recurranceCount = occurence;
      }
    }
    // else if (dateselect2new && swict_drop === true) {
    //   if (dateselect2new === "Never") {
    //     repeat_type = "Never";
    //   } else if (dateselect2new === "On") {
    //     untildate = onDate;
    //     repeat_type = "On";
    //   } else if (dateselect2new === "After") {
    //   }
    // }
    else {
      if (checking1) {
        untildate = "";
        repeat_type = "notCustom";
      } else {
        untildate = "";
        repeat_type = "norepeat";
      }
    }

    if (aptfromdate) {
      fromdate = aptfromdate;
    } else {
      fromdate = "";
    }

    if (apttodate) {
      todate = apttodate;
    } else {
      todate = "";
    }

    if (dayswise != null) {
      if (dayswise) {
        days_wise = true;
      } else {
        days_wise = false;
      }
    } else {
      days_wise = false;
    }

    var tempdatenew = moment(timedetnew, moment.defaultFormat).toDate();

    var startTimenew = moment(inTime, "h:mm A").format("hh:mm a");

    var returned_endatenew = moment(tempdatenew, "YYYY-MM-DD hh:mm a")
      .add(durationnew, "minutes")
      .format("YYYY-MM-DD hh:mm a");

    var startdatenew = moment(tempdatenew).format("YYYY-MM-DD");
    var secondsnew = convertHMS(durationnew);
    var endTimenew = moment(outTime, "h:mm A").format("hh:mm a");
    var endDatenew = moment(returned_endatenew, "YYYY-MM-DD HH:mm A").format(
      "YYYY-MM-DD"
    );
    var MeetMode = Telehealthplatform;

    var DurationMinutes = Math.round(secondsnew / 60);
    var Durationwithtext = DurationMinutes + " Min In patient";

    var article = {
      pc_pid: patientid,
      pc_catid: 0,
      pc_billid: 0,
      pc_aid: providerid,
      pc_title: statusid_temp,
      pc_duration: secondsnew,
      pc_hometext: "",
      pc_apptstatus: statusid_temp,
      pc_eventDate: startdatenew,
      pc_endDate: endDatenew,
      pc_startTime: startTimenew,
      pc_endTime: endTimenew,
      pc_facility: officeid,
      pc_informant: providerid,
      pc_billing_location: "10",
      pc_select1: select1,
      pc_select2: select2,
      pc_untildate: untildate ? moment(untildate).format("YYYY-MM-DD") : "",
      pc_lastdate: moment(lastdateApp).format("YYYY-MM-DD"),
      pc_fromdate: fromdate,
      pc_todate: todate,
      days_wise: days_wise,
      // allday: alldaynew,
      repeats: repeats,
      appttype: appttypenew,
      walkin: walkinnew,
      examRoom: roomval,
      id: authorized === "1" ? userID : main_pro,
      pc_repeat_type: repeat_type,
      week_and_every_day: Week_and_every,
      month_and_every_day: Month_and_every,
      Month_and_every_Interval: Month_and_every_Interval,
      ocurrance_count: recurranceCount ? recurranceCount : "",
      on_never_after: custChk,
      repeat_select: repvalue,
      pc_repeatselectmonth: repeatselectmonth,
      speciality,
      mode: MeetMode,
      billingFacilityId: billFacilityID,
    };
    let encryptedData = Encrypt_Value(article, "vozo");
    // if (checking1 === true) {
    //   var error = 0;
    //   if (days_wise === true) {
    //     if (repeats === "") {
    //       setselectdayError(true);
    //       error = 2;
    //     }
    //     if (untildate === "") {
    //       setuntildateError(true);
    //       error = 2;
    //     }
    //   } else if (days_wise === false) {
    //     if (repeatselectnew === "") {
    //       if (untildate === "") {
    //         setuntildateError(true);
    //         error = 2;
    //       }
    //       if (repeatselectnew === "") {
    //         seteverydayError(true);
    //         error = 2;
    //       }
    //     }
    //   }
    //   if (error > 0) {
    //     return false;
    //   }
    // }

    if (select1 === 0) {
      let error = 1;
      if (
        patientid === "" ||
        providerid === "" ||
        providerid === null ||
        providerid === undefined ||
        // statusid === "" ||
        officeid === "" ||
        speciality === ""
      ) {
        if (patientid === "") {
          setpatError(true);
          error = 2;
        }
        // if (billingid === "" && Appttype !== 2) {
        //   setBillingError(true);
        //   error = 2;
        // }
        if (
          providerid === "" ||
          providerid === null ||
          providerid === undefined
        ) {
          setprovError(true);
          error = 2;
        }
        // if (statusid === "") {
        //   setstatusError(true);
        //   error = 2;
        // }
        if (officeid === "") {
          setofficeError(true);
          error = 2;
        }
        if (speciality === "") {
          setSpecialError(true);
          error = 2;
        }

        if (error > 1) {
          return false;
        }
      } else {
        setSpinnerVal(true);

        if (statusid === "doc_confirmed" || statusid === "EMAIL") {
          setBtnDis(true);
          axiosInstance
            .get("/vozo/pat_mail_check?pid=" + patientid)
            .then((response) => {
              if (response.data == "pass") {
                props.setspinnerfornewapp();
                axiosInstance
                  .post("/vozo/appointment?pid=" + patientid, encryptedData)
                  .then(() => {
                    if (
                      MeetMode === "GMeet" &&
                      (statusid === "doc_confirmed" || statusid === "EMAIL")
                    ) {
                      axiosInstance
                        .get("/vozo/getGMeet/data")
                        .then((response) => {
                          var res = Decrypt_Value(response.data, "vozo");

                          let article = {
                            start_time: res["start_time"],
                            end_time: res["end_time"],
                            attendees: res["attendees"],
                            start: res["pc_startTime"],
                            end: res["pc_endTime"],
                            eventdate: res["pc_eventDate"],
                            eid: res["pc_eid"],
                            aid: res["pc_aid"],
                            pid: res["pc_pid"],
                            fac_id: res["pc_facility"],
                          };

                          axios
                            .get(
                              GMeetURL +
                                "/interface/customized/g-meet/index.php?action=create",
                              { params: article }
                            )
                            .then((res) => {
                              axiosInstance
                                .get("/vozo/tele/checkemailsent")
                                .then((response) => {
                                  setSpinnerVal(false);
                                  props.savealertmsg(response.data, true);
                                  setTimeout(() => props.popuphide(), 2000);
                                })
                                .catch((err) => {
                                  setSpinnerVal(false);
                                  console.error(err);
                                });
                              props.savealertmsg(
                                "New Appointment Added Successfully",
                                true
                              );
                              setTimeout(() => props.popuphide(), 2000);
                              props.FetchAppointments(
                                props.provarray,
                                props.facarray,
                                props.appFromDate,
                                props.apptoDate,
                                props.pat_share
                              );
                            })
                            .catch((err) => {
                              console.log("failed");
                            });
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    } else if (
                      statusid !== "doc_confirmed" &&
                      statusid !== "doc_cancelled" &&
                      statusid !== "doc_none" &&
                      statusid !== "doc_completed" &&
                      statusid === "EMAIL"
                    ) {
                      axiosInstance
                        .get("/vozo/EnableCalEvent/Data")
                        .then((response) => {
                          var res = Decrypt_Value(response.data, "vozo");
                          const googlecalendar = res.cal_en_data.filter(
                            (setting) =>
                              setting.gl_name === "Enable_calendar_Integration"
                          );
                          const outlookcalendar = res.cal_en_data.filter(
                            (setting) => setting.gl_name === "outlook_hotmail"
                          );
                          let article = {
                            start_time: res["start_time"],
                            end_time: res["end_time"],
                            attendees: res["attendees"],
                            pc_eid: res["pc_eid"],
                            repeatType: CalEvent,
                            fromWhere: "Provider",
                            forText: Durationwithtext,
                            onDate: moment(res["end_date"]).format(
                              "YYYY-MM-DD"
                            ),
                            fornormal: "yes",
                          };
                          if (googlecalendar.length > 0) {
                            if (googlecalendar[0].gl_value === "1") {
                              axios
                                .get(
                                  GMeetURL +
                                    "/interface/customized/g-meet/index.php?action=create",
                                  { params: article }
                                )
                                .then((res) => {})
                                .catch((err) => {
                                  console.log("failed");
                                });
                            }
                          }
                          if (outlookcalendar.length > 0) {
                            if (outlookcalendar[0].gl_value === "1") {
                              axios
                                .get(
                                  GMeetURL +
                                    "/interface/customized/outlook/create.php",
                                  {
                                    params: article,
                                  }
                                )
                                .then((res) => {})
                                .catch((err) => {
                                  console.log("failed");
                                });
                            }
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });

                      props.savealertmsg(
                        "New Appointment Added Successfully",
                        true
                      );
                      setTimeout(() => props.popuphide(), 2000);
                      props.FetchAppointments(
                        props.provarray,
                        props.facarray,
                        props.appFromDate,
                        props.apptoDate,
                        props.pat_share
                      );
                    } else {
                      if (
                        statusid === "doc_confirmed" ||
                        statusid === "EMAIL"
                      ) {
                        axiosInstance
                          .get("/vozo/tele/checkemailsent")
                          .then((response) => {
                            setSpinnerVal(false);
                            props.savealertmsg(response.data, true);
                            setTimeout(() => props.popuphide(), 2000);
                          })
                          .catch((err) => {
                            setSpinnerVal(false);
                            console.error(err);
                          });
                      }
                      props.savealertmsg(
                        "New Appointment Added Successfully",
                        true
                      );
                      setTimeout(() => props.popuphide(), 2000);
                      props.FetchAppointments(
                        props.provarray,
                        props.facarray,
                        props.appFromDate,
                        props.apptoDate,
                        props.pat_share
                      );
                    }
                  })
                  .catch((err) => console.error(err));
              } else {
                alert("Please update a valid Email in Patient Demographics");
                props.closepopup();
              }
              setBtnDis(false);
            })
            .catch((err) => {
              setSpinnerVal(false);
              setBtnDis(false);
              console.error(err);
            });
        } else {
          // props.setspinnerfornewapp();
          setBtnDis(true);
          axiosInstance
            .post("/vozo/appointment?pid=" + patientid, encryptedData)
            .then(() => {
              if (
                MeetMode === "GMeet" &&
                (statusid === "doc_confirmed" || statusid === "EMAIL")
              ) {
                axiosInstance
                  .get("/vozo/getGMeet/data")
                  .then((response) => {
                    var res = Decrypt_Value(response.data, "vozo");

                    let article = {
                      start_time: res["start_time"],
                      end_time: res["end_time"],
                      attendees: res["attendees"],
                      start: res["pc_startTime"],
                      end: res["pc_endTime"],
                      eventdate: res["pc_eventDate"],
                      eid: res["pc_eid"],
                      aid: res["pc_aid"],
                      pid: res["pc_pid"],
                    };

                    axios
                      .get(
                        GMeetURL +
                          "/interface/customized/g-meet/index.php?action=create",
                        { params: article }
                      )
                      .then((res) => {})
                      .catch((err) => {
                        console.log("failed");
                      });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              } else if (
                statusid !== "doc_confirmed" ||
                statusid !== "doc_cancelled" ||
                statusid !== "doc_none" ||
                statusid !== "doc_completed" ||
                statusid === "EMAIL"
              ) {
                axiosInstance
                  .get("/vozo/EnableCalEvent/Data")
                  .then((response) => {
                    var res = Decrypt_Value(response.data, "vozo");
                    const googlecalendar = res.cal_en_data.filter(
                      (setting) =>
                        setting.gl_name === "Enable_calendar_Integration"
                    );
                    const outlookcalendar = res.cal_en_data.filter(
                      (setting) => setting.gl_name === "outlook_hotmail"
                    );
                    let article = {
                      start_time: res["start_time"],
                      end_time: res["end_time"],
                      attendees: res["attendees"],
                      pc_eid: res["pc_eid"],
                      repeatType: CalEvent,
                      fromWhere: "Provider",
                      forText: Durationwithtext,
                      onDate: moment(res["end_date"]).format("YYYY-MM-DD"),
                      fornormal: "yes",
                    };
                    if (googlecalendar.length > 0) {
                      if (googlecalendar[0].gl_value === "1") {
                        axios
                          .get(
                            GMeetURL +
                              "/interface/customized/g-meet/index.php?action=create",
                            { params: article }
                          )
                          .then((res) => {})
                          .catch((err) => {
                            console.log("failed");
                          });
                      }
                    }
                    if (outlookcalendar.length > 0) {
                      if (outlookcalendar[0].gl_value === "1") {
                        axios
                          .get(
                            GMeetURL +
                              "/interface/customized/outlook/create.php",
                            {
                              params: article,
                            }
                          )
                          .then((res) => {})
                          .catch((err) => {
                            console.log("failed");
                          });
                      }
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }
              if (statusid === "doc_confirmed") {
                axiosInstance
                  .get("/vozo/getGMeet/data")
                  .then((response) => {
                    console.log(response);
                  })
                  .catch((err) => {
                    setSpinnerVal(false);
                    console.error(err);
                  });

                axiosInstance
                  .get("/vozo/tele/checkemailsent")
                  .then((response) => {
                    setSpinnerVal(false);
                    props.savealertmsg(response.data, true);
                    setTimeout(() => props.popuphide(), 2000);
                  })
                  .catch((err) => {
                    setSpinnerVal(false);
                    console.error(err);
                  });
              }

              props.savealertmsg("New Appointment Added Successfully", true);
              setSpinnerVal(false);
              setTimeout(() => props.popuphide(), 2000);
              props.FetchAppointments(
                props.provarray,
                props.facarray,
                props.appFromDate,
                props.apptoDate,
                props.pat_share
              );
              setBtnDis(false);
            })
            .catch((err) => {
              console.error(err);
              props.closepopup();
              setBtnDis(false);
            });
        }
      }
    } else if (select1 > 0) {
      let error = 1;
      if (
        patientid === "" ||
        providerid === "" ||
        providerid === null ||
        providerid === undefined ||
        // statusid === "" ||
        officeid === "" ||
        speciality === ""
        // ||
        // untildate === ""
      ) {
        if (patientid === "") {
          setpatError(true);
          error = 2;
        }

        // if (roomval === "") {
        //   setexamroomError(true);
        //   error = 2;
        // }
        // if (billingid === "" && Appttype !== 2) {
        //   setBillingError(true);
        //   error = 2;
        // }
        if (
          providerid === "" ||
          providerid === null ||
          providerid === undefined
        ) {
          setprovError(true);
          error = 2;
        }
        // if (statusid === "") {
        //   setstatusError(true);
        //   error = 2;
        // }
        if (officeid === "") {
          setofficeError(true);
          error = 2;
        }

        if (speciality === "") {
          setSpecialError(true);
          error = 2;
        }

        if (untildate === "") {
          setuntildateError(true);
          error = 2;
        }

        if (error > 1) {
          return false;
        }
      } else {
        if (statusid === "doc_confirmed") {
          setBtnDis(true);
          axiosInstance
            .get("/vozo/pat_mail_check?pid=" + patientid)
            .then((response) => {
              if (response.data === "pass") {
                // props.setspinnerfornewapp();
                setSpinnerVal(false);

                axiosInstance
                  .post("/vozo/appointment?pid=" + patientid, encryptedData)
                  .then(() => {
                    if (
                      MeetMode === "GMeet" &&
                      (statusid === "doc_confirmed" || statusid === "EMAIL")
                    ) {
                      axiosInstance
                        .get("/vozo/getGMeet/data")
                        .then((response) => {
                          var res = Decrypt_Value(response.data, "vozo");
                          let article = {
                            start_time: res["start_time"],
                            end_time: res["end_time"],
                            attendees: res["attendees"],
                            start: res["pc_startTime"],
                            end: res["pc_endTime"],
                            eventdate: res["pc_eventDate"],
                            eid: res["pc_eid"],
                            aid: res["pc_aid"],
                            pid: res["pc_pid"],
                          };
                          axios
                            .get(
                              GMeetURL +
                                "/interface/customized/g-meet/index.php?action=create",
                              { params: article }
                            )
                            .then((res) => {})
                            .catch((err) => {
                              console.log("failed");
                            });
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    } else if (
                      statusid !== "doc_confirmed" ||
                      statusid !== "doc_cancelled" ||
                      statusid !== "doc_none" ||
                      statusid !== "doc_completed" ||
                      statusid === "EMAIL"
                    ) {
                      axiosInstance
                        .get("/vozo/EnableCalEvent/Data")
                        .then((response) => {
                          var res = Decrypt_Value(response.data, "vozo");
                          const googlecalendar = res.cal_en_data.filter(
                            (setting) =>
                              setting.gl_name === "Enable_calendar_Integration"
                          );
                          const outlookcalendar = res.cal_en_data.filter(
                            (setting) => setting.gl_name === "outlook_hotmail"
                          );
                          let article = {
                            start_time: res["start_time"],
                            end_time: res["end_time"],
                            attendees: res["attendees"],
                            pc_eid: res["pc_eid"],
                            repeatType: CalEvent,
                            fromWhere: "Provider",
                            forText: Durationwithtext,
                            onDate: moment(res["end_date"]).format(
                              "YYYY-MM-DD"
                            ),
                            fornormal: "yes",
                          };
                          if (googlecalendar.length > 0) {
                            if (googlecalendar[0].gl_value === "1") {
                              axios
                                .get(
                                  GMeetURL +
                                    "/interface/customized/g-meet/index.php?action=create",
                                  { params: article }
                                )
                                .then((res) => {})
                                .catch((err) => {
                                  console.log("failed");
                                });
                            }
                          }
                          if (outlookcalendar.length > 0) {
                            if (outlookcalendar[0].gl_value === "1") {
                              axios
                                .get(
                                  GMeetURL +
                                    "/interface/customized/outlook/create.php",
                                  {
                                    params: article,
                                  }
                                )
                                .then((res) => {})
                                .catch((err) => {
                                  console.log("failed");
                                });
                            }
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                    if (statusid === "doc_confirmed") {
                      axiosInstance
                        .get("/vozo/tele/checkemailsent")
                        .then((response) => {
                          props.savealertmsg(response.data, true);
                          setTimeout(() => props.popuphide(), 2000);
                        })
                        .catch((err) => console.error(err));
                    }

                    props.savealertmsg(
                      "New Appointment Added Successfully",
                      true
                    );
                    setTimeout(() => props.popuphide(), 2000);
                    props.FetchAppointments(
                      props.provarray,
                      props.facarray,
                      props.appFromDate,
                      props.apptoDate,
                      props.pat_share
                    );
                  })
                  .catch((err) => console.error(err));
              } else {
                alert("Please update a valid Email in Patient Demographics");
                props.closepopup();
              }
              setBtnDis(false);
            })
            .catch((err) => {
              console.error(err);
              setBtnDis(false);
            });
        } else {
          // props.setspinnerfornewapp();
          setBtnDis(true);
          await axiosInstance
            .post("/vozo/appointment?pid=" + patientid, encryptedData)
            .then(() => {
              if (
                MeetMode === "GMeet" &&
                (statusid === "doc_confirmed" || statusid === "EMAIL")
              ) {
                axiosInstance
                  .get("/vozo/getGMeet/data")
                  .then((response) => {
                    var res = Decrypt_Value(response.data, "vozo");
                    let article = {
                      start_time: res["start_time"],
                      end_time: res["end_time"],
                      attendees: res["attendees"],
                      start: res["pc_startTime"],
                      end: res["pc_endTime"],
                      eventdate: res["pc_eventDate"],
                      eid: res["pc_eid"],
                      aid: res["pc_aid"],
                      pid: res["pc_pid"],
                    };
                    axios
                      .get(
                        GMeetURL +
                          "/interface/customized/g-meet/index.php?action=create",
                        { params: article }
                      )
                      .then((res) => {})
                      .catch((err) => {
                        console.log("failed");
                      });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              } else if (
                statusid !== "doc_confirmed" ||
                statusid !== "doc_cancelled" ||
                statusid !== "doc_none" ||
                statusid !== "doc_completed" ||
                statusid === "EMAIL"
              ) {
                axiosInstance
                  .get("/vozo/EnableCalEvent/Data")
                  .then((response) => {
                    var res = Decrypt_Value(response.data, "vozo");
                    const googlecalendar = res.cal_en_data.filter(
                      (setting) =>
                        setting.gl_name === "Enable_calendar_Integration"
                    );
                    const outlookcalendar = res.cal_en_data.filter(
                      (setting) => setting.gl_name === "outlook_hotmail"
                    );
                    let article = {
                      start_time: res["start_time"],
                      end_time: res["end_time"],
                      attendees: res["attendees"],
                      pc_eid: res["pc_eid"],
                      repeatType: CalEvent,
                      fromWhere: "Provider",
                      forText: Durationwithtext,
                      onDate: moment(res["end_date"]).format("YYYY-MM-DD"),
                      fornormal: "yes",
                    };
                    if (googlecalendar.length > 0) {
                      if (googlecalendar[0].gl_value === "1") {
                        axios
                          .get(
                            GMeetURL +
                              "/interface/customized/g-meet/index.php?action=create",
                            { params: article }
                          )
                          .then((res) => {})
                          .catch((err) => {
                            console.log("failed");
                          });
                      }
                    }
                    if (outlookcalendar.length > 0) {
                      if (outlookcalendar[0].gl_value === "1") {
                        axios
                          .get(
                            GMeetURL +
                              "/interface/customized/outlook/create.php",
                            {
                              params: article,
                            }
                          )
                          .then((res) => {})
                          .catch((err) => {
                            console.log("failed");
                          });
                      }
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }
              if (statusid === "doc_confirmed") {
                axiosInstance
                  .get("/vozo/tele/checkemailsent")
                  .then((response) => {
                    props.savealertmsg(response.data, true);
                    setTimeout(() => props.popuphide(), 2000);
                  })
                  .catch((err) => console.error(err));
              }

              props.savealertmsg("New Appointment Added Successfully", true);
              setTimeout(() => props.popuphide(), 2000);
              props.FetchAppointments(
                props.provarray,
                props.facarray,
                props.appFromDate,
                props.apptoDate,
                props.pat_share
              );
              setBtnDis(false);
            })
            .catch((err) => {
              console.error(err);
              props.closepopup();
              setBtnDis(false);
            });
        }
      }
    }
  };

  const checkFacility = (type) => {
    if (type === "classname") {
      // if (videochck) {
      //   return telehealth && telehealth.length === 0 ? "hide-label" : "";
      // } else {
      return sourceoffice && sourceoffice.length === 0 ? "hide-label" : "";
      // }
    } else if (type === "spinner") {
      // if (videochck) {
      //   return telehealth &&
      //     officeid &&
      //     telehealth.length === 0 &&
      //     officeid.length > 0 ? (
      //     <Spinner
      //       animation="border"
      //       size="sm"
      //       variant="primary"
      //       className="add-spinner-sty"
      //     />
      //   ) : (
      //     ""
      //   );
      // } else {
      return sourceoffice &&
        officeid &&
        sourceoffice.length === 0 &&
        officeid.length > 0 ? (
        <Spinner
          animation="border"
          size="sm"
          variant="primary"
          className="add-spinner-sty"
        />
      ) : (
        ""
      );
      // }
    }
  };

  const onChange = (timeStandard, typeTime) => {
    if (Appttype === 2 && timeStandard === "Invalid date") {
      return;
    }

    if (timeStandard === "Invalid date" && typeTime === "inTime") {
      setInTime(moment(start).format("h:mm A"));
      setOutTime(
        moment(moment(start, "h:mm A").add(15, "minutes")).format("h:mm A")
      );

      let out = moment(moment(start, "h:mm A").add(15, "minutes")).format(
        "h:mm A"
      );
      let dur = moment(out, "hh:mm a").diff(
        moment(inTime, "hh:mm a"),
        "minutes"
      );
      setEditing(dur);
    } else if (timeStandard === "Invalid date" && typeTime === "outTime") {
      setOutTime(
        moment(moment(inTime, "h:mm A").add(15, "minutes")).format("h:mm A")
      );

      let out = moment(moment(inTime, "h:mm A").add(15, "minutes")).format(
        "h:mm A"
      );

      let dur = moment(outTime, "hh:mm a").diff(
        moment(out, "hh:mm a"),
        "minutes"
      );
      setEditing(dur);
    } else {
      let time = timeStandard.split(":");
      let timeMin = time[1].split(" ");

      if (typeTime === "inTime") {
        let intim = moment(timeStandard, "h:mm A").format("h:mm A");
        let out = moment(
          moment(timeStandard, "h:mm A").add(15, "minutes")
        ).format("h:mm A");
        let dur = moment(out, "hh:mm a").diff(
          moment(intim, "hh:mm a"),
          "minutes"
        );
        setEditing(dur);

        setInTimeErr(false);
        setOutTime(
          moment(moment(timeStandard, "h:mm A").add(15, "minutes")).format(
            "h:mm A"
          )
        );
        if (timeMin[1] === "AM") {
          if (time[0] === "12") {
            time[0] = Number(time[0]) - 12;
          }
          if (time[0] < Number(startTime)) {
            settimelimiterrorbox(true);
            return false;
          } else {
            settimelimiterrorbox(false);
          }
        } else {
          if (time[0] !== "12") {
            time[0] = Number(time[0]) + 12;
          }
          if (time[0] >= Number(endTime)) {
            settimelimiterrorbox(true);
            return false;
          } else {
            settimelimiterrorbox(false);
          }
        }
        setapttime(timeStandard);
      } else {
        let inTimeNew = inTime.split(":");
        let inTimeMin = inTimeNew[1].split(" ");

        let out = moment(timeStandard, "h:mm A").format("h:mm A");
        let duration = moment(out, "hh:mm a").diff(
          moment(inTime, "hh:mm a"),
          "minutes"
        );
        setEditing(duration);

        if (inTime === timeStandard) {
          setInTimeErr(true);
        }
        if (inTime !== timeStandard) {
          setInTimeErr(false);
        }
        if (timeMin[1] === "AM") {
          if (
            time[0] > Number(endTime) ||
            inTimeNew[0] < Number(startTime) ||
            inTimeNew[0] === "12"
          ) {
            setInTimeErr(true);
            return false;
          } else if (
            inTimeMin[1] === "PM" ||
            Number(inTimeNew[0]) > Number(time[0]) ||
            (inTimeNew[0] === time[0] && inTimeMin[0] >= timeMin[0]) ||
            (time[0] === inTimeNew[0] && timeMin[0] <= inTimeMin[0])
          ) {
            setInTimeErr(true);
            return false;
          } else if (time[0] === "12") {
            settimelimiterrorbox(true);
          } else if (
            Number(inTimeNew[0]) > time[0] ||
            (Number(inTimeNew[0]) === time[0] && inTimeMin[0] > timeMin[0])
          ) {
            setInTimeErr(true);
            return false;
          } else {
            setInTimeErr(false);
            settimelimiterrorbox(false);
          }
        } else {
          if (Number(time[0]) !== 12) {
            time[0] = Number(time[0]) + 12;
          }
          if (inTimeMin[1] === "PM" && Number(inTimeNew[0]) !== 12) {
            inTimeNew[0] = Number(inTimeNew[0]) + 12;
          }
          if (inTimeMin[1] === "AM" && Number(inTimeNew[0]) === 12) {
            inTimeNew[0] = 0;
          }
          if (Number(time[0]) === Number(endTime) && Number(timeMin[0]) > 0) {
            settimelimiterrorbox(true);
            return false;
          }
          if (Number(time[0]) === Number(endTime) && Number(timeMin[0]) == 0) {
            settimelimiterrorbox(false);
          }
          if (Number(time[0]) > Number(endTime)) {
            settimelimiterrorbox(true);
            return false;
          }
          if (Number(time[0]) < Number(endTime)) {
            settimelimiterrorbox(false);
          }
          if (Number(inTimeNew[0]) > Number(time[0])) {
            settimelimiterrorbox(true);
            return false;
          }
          if (Number(inTimeNew[0]) < Number(time[0])) {
            settimelimiterrorbox(false);
          }
          if (
            Number(inTimeNew[0]) === Number(time[0]) &&
            Number(inTimeMin[0]) > Number(timeMin[0])
          ) {
            setInTimeErr(true);
            return false;
          }
          if (
            Number(inTimeNew[0]) === Number(time[0]) &&
            Number(inTimeMin[0]) < Number(timeMin[0])
          ) {
            setInTimeErr(false);
          }
          if (Number(inTimeNew[0]) < Number(startTime)) {
            settimelimiterrorbox(true);
          }
        }
      }
    }
  };

  const fornormalapp = () => {
    return (
      <>
        <div className="for-get-started-popup-for-res-2">
          <div className="dis-fl-just">
            <div className="get-st-billinfo-head">Add Appointment</div>
          </div>
          <div className="get-st-client-content-3">
            Schedule your medical appointment with your preferred doctor.
          </div>

          <div className="get-st-box-with-rad-but-2">
            <>
              <Radio.Group value={1}>
                <Radio value={1}></Radio>
                <Radio value={2}></Radio>
              </Radio.Group>
            </>

            <Button
              className="save_active get-st-next-btn"
              variant="primary"
              onClick={() => forexploreteleapp()}
            >
              Next
            </Button>
          </div>
        </div>
      </>
    );
  };

  const forteleapp = () => {
    return (
      <>
        <div className="for-get-started-popup-for-res-2">
          <div className="dis-fl-just">
            <div className="get-st-billinfo-head">Add Tele Appointment</div>
          </div>
          <div className="get-st-client-content-3">
            Select the facility and schedule a video visit on your own.
          </div>

          <div className="get-st-box-with-rad-but-2">
            <div className="m-t-10">
              <Radio.Group value={2}>
                <Radio value={1}></Radio>
                <Radio value={2}></Radio>
              </Radio.Group>
            </div>

            <Button
              className="save_active get-st-next-btn"
              variant="primary"
              onClick={() => forexploreteleappdone()}
            >
              Done
            </Button>
          </div>
        </div>
      </>
    );
  };

  const WeekdayChange = (val) => {
    setweekday(val);
    setselectdayError(false);
  };

  const forexploreteleapp = () => {
    localStorage.removeItem("normalapppopup");
    setnormalappointment(false);
    setforteleornor(2);
    setteleappointment(true);
  };

  const forexploreteleappdone = () => {
    setforteleornor(1);
    setteleappointment(false);
  };

  const hidehr = () => {
    document.getElementById("hide").className = "hide-hr";
  };

  const changeborder2 = () => {
    document.getElementById("border2").className = "border2";
  };

  const changeborder1 = () => {
    document.getElementById("border1").className = "border1";
  };

  const changeborder = () => {
    document.getElementById("border").className = "border0";
  };

  const handleHoursInput = (e) => {
    let val = parseInt(e.target.value, 10);
    if (isNaN(val)) {
      setEditing("");
    } else {
      val = val >= 0 ? val : 0;
      setEditing(val);
      settimelimiterrorbox(false);
      settimelimiterror("none");
      setBtnDis(false);
    }
  };

  const handleDropDownSelectOffice = (event, data) => {
    setproviderid("");
    setSpeciality("");
    setofficeid(data.value);
    setofficeError(false);
    // getRoom(data.value);
  };

  const changeSpeciality = (data) => {
    setproviderid("");
    setSpeciality(data);
    setSpecialError(false);
  };

  const handleDropDownSelectStatus = (event, data) => {
    setstatusid(data.value);
    setstatusError(false);
  };

  const handleDropDownSelectProvider = (event, data) => {
    if (provError) {
      setprovError(false);
    }
    setproviderid(data.value);
    setprovError(false);
  };

  const handleBillingFacility = (event, data) => {
    setBillFacilityID(data.value);
    setBillFacilityError(false);
  };

  const handleDropDownSelectPatient = (event, data) => {
    setpatientid(data.value);
    setpatError(false);
  };

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const getpatientsrchtext = (e) => {
    setSource([]);
    setfornoresults("1");
    setpatsrchvalue(e.target.value);
  };

  const fdate = (fdate) => {
    if (fdate) {
      setaptdate(moment(fdate).format("YYYY-MM-DD"));
    }
  };

  const check1 = () => {
    setchecking1(!checking1);
    setStatus(!status);
    setrepeatselectnew("");
    setrepeatselect2new("");
    setdayswise(false);
    setweekday("");
    setdateselect2new("");
  };

  const walkinclick = () => {
    setwalkincheck(!walkincheck);
  };

  const onChangeRadio = (e) => {
    if (!storedNames.appt_a && telehealth[0]?.value != officeid) {
      setAppttype(1);
      setvideochck(false);
      alert("Provider's facility is not a telehealth facility");
    } else {
      setAppttype(e.target.value);
      if (e.target.value == 2) {
        //check selected times
        let t1 = inTime.split(" ");
        let t2 = outTime.split(" ");

        let from = disabledFromTimes(t1[0], t1[1], e.target.value);
        let to = disabledToTime(t2[0], t2[1], fromId, e.target.value);

        if (!from) {
          let intime = moment(start);
          let now = moment();

          if (intime.isAfter(now)) {
            if (props.view === "month") {
              let intime = moment(props.startTime1, "H").format("h:mm A");
              setInTime(intime);

              let outtime = moment(intime, "h:mm A")
                .add(15, "minutes")
                .format("h:mm A");
              setOutTime(outtime);

              let dur = moment(outtime, "h:mm A").diff(
                moment(intime, "h:mm A"),
                "minutes"
              );
              setEditing(dur);
            } else {
              let intime = moment(start).format("h:mm A");
              setInTime(moment(start).format("h:mm A"));

              let out = moment(
                moment(start, "h:mm A").add(15, "minutes")
              ).format("h:mm A");
              setOutTime(out);
              let dur = moment(out, "hh:mm a").diff(
                moment(intime, "hh:mm a"),
                "minutes"
              );
              setEditing(dur);
            }
          }
        } else {
          let now = moment();

          let intime = now.format("h:mm A");

          let checktime = moment(aptdate, "DD-MM-YYYY h:mm a");

          if (checktime.isBefore(now.format("DD-MM-YYYY"))) {
            setInTime("");
            setOutTime("");
            setEditing("");
          } else {
            let out = moment(
              moment(now.format("h:mm A"), "h:mm A").add(15, "minutes")
            ).format("h:mm A");

            setInTime(intime);
            setOutTime(out);
            let dur = moment(out, "hh:mm a").diff(
              moment(now, "hh:mm a"),
              "minutes"
            );
            setEditing(dur);
            setapttime(intime);
          }
        }
        // setofficeid(telehealth[0] ? telehealth[0].value : "");
        setprofileError(false);
        // setEditing("");
        setstatusid("");
        setbillingid("");
      } else if (e.target.value == 1) {
        let now = moment();

        let intime = now.format("h:mm A");

        let checktime = moment(aptdate, "DD-MM-YYYY h:mm a");
        if (checktime.isBefore(now.format("DD-MM-YYYY"))) {
          setInTime("");
          setOutTime("");
          setEditing("");
        } else {
          if (props.view === "month") {
            let inmonth = moment(props.startTime1, "H").format("h:mm A");
            setInTime(moment(props.startTime1, "H").format("h:mm A"));

            let out = moment(
              moment(props.startTime1, "H").add(15, "minutes")
            ).format("h:mm A");
            setOutTime(out);
            let dur = moment(out, "hh:mm a").diff(
              moment(inmonth, "hh:mm a"),
              "minutes"
            );
            setEditing(dur);
            setapttime(inmonth);
          } else {
            let out = moment(
              moment(now.format("h:mm A"), "h:mm A").add(15, "minutes")
            ).format("h:mm A");

            setInTime(intime);
            setOutTime(out);
            let dur = moment(out, "hh:mm a").diff(
              moment(now, "hh:mm a"),
              "minutes"
            );
            setEditing(dur);
            setapttime(intime);
          }
        }
        setofficeid(props.officeId);
        setstatusid("");
        setbillingid("");
      }
    }
  };

  const days_check = () => {
    setswict_check(!swict_check);
    // setswict_drop(!swict_drop);
    setrepeatselectnew("");
    setrepeatselect2new("");
    setdayswise(!dayswise);
    setweekday("");
    setselectdayError(false);
    setuntildateError(false);
    seteverydayError(false);
  };

  // const alldaycheck = () => {
  //   setalldaydisable(!alldaydisable);
  //   setallday(!allday);
  // };

  const checkvideovisit = () => {
    const currentTime = moment();
    if ((accountType === "1" && teleFlag === "0") || accountType === "-1") {
      setvideochck(false);
    } else {
      if (Appttype !== 3) {
        setproviderid("");
      }
      setvideochck(true);
      if (props.view === "month") {
        let intime = moment(props.startTime1, "H").format("h:mm A");
        setInTime(intime);

        let outtime = moment(intime, "h:mm A")
          .add(15, "minutes")
          .format("h:mm A");
        setOutTime(outtime);

        let dur = moment(outtime, "h:mm A").diff(
          moment(intime, "h:mm A"),
          "minutes"
        );
        setEditing(dur);
      } else {
        setInTime(currentTime.format("h:mm A"));
        setOutTime(moment(currentTime.add(15, "minutes")).format("h:mm A"));
      }
    }
  };
  const checkapt = () => {
    if (Appttype !== 3) {
      setproviderid("");
    }
    setvideochck(false);
  };
  const disabledDate = (current) => {
    if (dateselect2new !== "") {
      let a = current.isAfter(dateselect2new);

      if (workDays === 1) {
        return moment(current).day() === 0 || a;
      } else if (workDays === 2) {
        return moment(current).day() === 0 || moment(current).day() === 6 || a;
      } else if (workDays === 0) {
        return a;
      }
    } else {
      if (workDays === 1) {
        return moment(current).day() === 0;
      } else if (workDays === 2) {
        return moment(current).day() === 0 || moment(current).day() === 6;
      }
    }
  };

  // const handleRoomVal = (data) => {
  //   setRoomVal(data);
  //   setexamroomError(false);
  // };

  const handleUntilDate = (e) => {
    setdateselect2new(e);
    document.getElementById("new-app-untildate").style.border =
      "1px solid #ced4da";
    setuntildateError(false);
  };
  const maxDate = aptdate
    ? moment(aptdate).add(1, "year").format("YYYY-MM-DD")
    : undefined;
  const handleSelect2 = (id, val) => {
    if (id === "new-app-select1") {
      setrepeatselectnew(val);
      seteverydayError(false);
    } else {
      setweekday("");
      if (val === "1") {
        setrepeatselect2new("1");
        setdayswise(true);
        setswict_check(true);

        setWeek_and_every(true);
        setswict_check_mnth(false);
      } else if (val === "2") {
        setrepeatselect2new("2");
        setswict_check_mnth(true);
        setswict_check(false);

        setdayswise(false);
        setWeek_and_every(false);
      } else if (val === "3") {
        setrepeatselect2new("3");
        setswict_check_mnth(false);
        setswict_check(false);

        setdayswise(false);
        setWeek_and_every(false);
      } else {
        setswict_check(false);

        setdayswise(false);
        setWeek_and_every(false);
        setswict_check_mnth(false);
      }
      setrepeatselect2new(val);
    }
  };

  const getDatas = async () => {
    if (!props.pid) {
      let alle_data =
        "limit=40&authorized=" +
        authorized +
        "&main_pro=" +
        main_pro +
        "&userID=" +
        userID +
        "&pat_share=" +
        storedNames.pat_share +
        "&owner_id=" +
        owner_id +
        "&activepatlist=" +
        1;

      let alle_data_enc = Encrypt_Value(alle_data, "vozo");

      await axiosInstance
        .get("/vozo/patientsearch?search=" + alle_data_enc)
        .then((response) => {
          let temp_role = Decrypt_Value(response.data, "vozo");

          setSource(temp_role);
        })
        .catch(() => {
          setSource([]);
          setfornoresults("");
        });
    }
    // let data =
    //   "pat_share=" +
    //   storedNames.appt_a +
    //   "&main_pro=" +
    //   main_pro +
    //   "&owner_id=" +
    //   owner_id +
    //   "&userID=" +
    //   userID +
    //   "&authorized=" +
    //   authorized +
    //   "&facilityId=" +
    //   props.officeId +
    //   "&speciality=" +
    //   speciality;

    // let data1 = Encrypt_Value(data, "vozo");

    // let url;
    // if (storedNames.appt_a) {
    //   url = "/vozonew/provider?search=" + data1;
    // } else {
    //   url = "/vozonew/provider?uid=" + userID;
    // }

    // await axiosInstance
    //   .get(url)
    //   .then((response) => {
    //     let temp_role_prov = Decrypt_Value(response.data, "vozo");
    //     setSourceprovider(temp_role_prov);
    //     console.log(temp_role_prov);
    //   })
    //   .catch((err) => console.error(err));

    // await axiosInstance
    //   .get("/vozo/category")
    //   .then((response) => {
    //     let temp_role_cat = Decrypt_Value(response.data, "vozo");
    //     setSourceprofile(temp_role_cat);
    //   })
    //   .catch((err) => console.error(err));

    // await axiosInstance
    //   .get("/vozo/category?cat_id=16")
    //   .then((response) => {
    //     let temp_role_cat = Decrypt_Value(response.data, "vozo");
    //     setteleprofile(temp_role_cat);
    //   })
    //   .catch((err) => console.error(err));

    await axiosInstance
      .get("/vozo/appstatus/category")
      .then((response) => {
        let temp_role_cat = Decrypt_Value(response.data, "vozo");
        setSourcestatus(temp_role_cat[0]);
        setSourcestatustele(temp_role_cat[1]);
        // setSourceprofile(temp_role_cat[2]);
        // setteleprofile(temp_role_cat[2]);
        // setBillingProfile(temp_role_cat[4] ?? []);
        setSpinnerVal(false);
      })
      .catch((err) => console.error(err));

    let Data_frsh_doc =
      "authorized=" +
      authorized +
      "&main_pro=" +
      main_pro +
      "&group_id=" +
      group_id;
    let Data_enc_doc = Encrypt_Value(Data_frsh_doc, "vozo");
    await axiosInstance
      .get("/vozonew/facility?search=" + Data_enc_doc)
      .then((response) => {
        let decryptval = Decrypt_Value(response.data, "vozo");
        setSourceoffice(decryptval);
      })
      .catch((err) => console.error(err));

    await axiosInstance
      .get("/vozonew/facility/telehealth")
      .then((response) => {
        let temp_role_tele_cat = Decrypt_Value(response.data, "vozo");
        settelehealth(temp_role_tele_cat);
      })
      .catch((err) => console.error(err));

    // await axiosInstance
    //   .get("/vozo/appstatus")
    //   .then((response) => {
    //     let temp_role_appt_stat = Decrypt_Value(response.data, "vozo");
    //     setSourcestatus(temp_role_appt_stat);
    //     setSpinnerVal(false);
    //   })
    //   .catch((err) => console.error(err));
    // await axiosInstance
    //   .get("/vozo/appstatus/tele")
    //   .then((response) => {
    //     let temp_role_tele_stat = Decrypt_Value(response.data, "vozo");
    //     setSourcestatustele(temp_role_tele_stat);
    //   })
    //   .catch((err) => console.error(err));

    if (props.pid !== undefined) {
      setpatientid(props.pid);
    }
    if (props.pid !== undefined) {
      await axiosInstance
        .get("/vozonew/patient?pid=" + props.pid)
        .then((response) => {
          let temp_role_pat_flag = Decrypt_Value(response.data, "vozo");

          let alle_data =
            "limit=40&fname=" +
            temp_role_pat_flag.fname.toLowerCase() +
            "&authorized=" +
            authorized +
            "&main_pro=" +
            main_pro +
            "&userID=" +
            userID +
            "&pat_share=" +
            storedNames.pat_share +
            "&owner_id=" +
            owner_id +
            "&activepatlist=" +
            1;

          let alle_data_enc = Encrypt_Value(alle_data, "vozo");

          axiosInstance
            .get("/vozo/patientsearch?search=" + alle_data_enc)
            .then((response) => {
              let temp_role = Decrypt_Value(response.data, "vozo");

              setSource(temp_role); // get 10 datas from response.data main array
              setfornoresults(temp_role);
            })
            .catch(() => {
              setSource([]);
              setfornoresults("");
            });

          setpatientid(temp_role_pat_flag.pid);
        })
        .catch((err) => console.error(err));
    }
  };
  const handleCustomCalendar = (e) => {
    if (
      e.target.id !== "CustomDatePicker" &&
      e.target.id !== "CustomDatePickerbuttons" &&
      e.target.id !== "date" &&
      e.target.id !== "date1" &&
      e.target.className !== "year"
    ) {
      closePicker();
      closePicker1();
      closePicker3();
    }
  };
  const dateSelect = (value) => {
    if (Object.prototype.toString.call(value) === "[object Date]") {
      setaptdate(moment(value).format("MM-DD-YYYY"));
      setValidDate(new Date(value));
    } else {
      setaptdate("");
      setValidDate("");
    }
    closePicker();
  };
  const dateSelect1 = (value) => {
    if (Object.prototype.toString.call(value) === "[object Date]") {
      setOnDate(moment(value).format("MM-DD-YYYY"));
      setdateselect2new("On");
      setcustChk(2);
      setValidDate1(new Date(value));
    } else {
      setOnDate("");
      setValidDate("");
    }
    closePicker1();
  };
  const dateSelect3 = (value) => {
    if (Object.prototype.toString.call(value) === "[object Date]") {
      setLastdateApp(moment(value).format("MM-DD-YYYY"));
      setValidDate3(new Date(value));
    } else {
      setLastdateApp("");
      setValidDate3("");
    }
    closePicker3();
  };
  const closePicker = () => {
    setShowPicker(false);
  };
  const closePicker1 = () => {
    setShowPicker1(false);
  };
  const closePicker3 = () => {
    setShowPicker3(false);
  };
  const handlepicker = (e) => {
    e.preventDefault();
    setShowPicker(true);
  };
  const handlepicker1 = (e) => {
    e.preventDefault();
    setShowPicker1(true);
  };
  const handlepicker3 = (e) => {
    e.preventDefault();
    setShowPicker3(true);
  };
  const keyDownExpiry = (event) => {
    const inp = event.target;
    const key = event.keyCode || event.charCode;

    const inputValue = inp.value;
    const numericValue = inputValue.replace(/[^\d]/g, "");
    const caretPosition = inp.selectionStart;

    if (key !== 8 && key !== 46) {
      // Check if key pressed is not backspace (8) or delete (46)
      if (numericValue.length === 2 && caretPosition === 2) {
        inp.value = numericValue + "-"; // Auto-insert hyphen after the first 2 digits
      } else if (numericValue.length === 4 && caretPosition === 5) {
        inp.value =
          numericValue.slice(0, 2) + "-" + numericValue.slice(2, 4) + "-"; // Auto-insert hyphens after 2nd and 4th digits
      } else if (numericValue.length > 4 && caretPosition === 10) {
        inp.value =
          numericValue.slice(0, 2) +
          "-" +
          numericValue.slice(2, 4) +
          "-" +
          numericValue.slice(4, 8); // Auto-insert hyphens after 2nd, 4th, and 8th digits
      }
    }
  };
  const handlecloseUpgrade = () => setUpgrade(false);

  // ------------------------------ Functions End--------------------------------------------------- //

  // ------------------------------ useEffect Start--------------------------------------------------- //
  useEffect(() => {
    if (aptdate !== "") {
      var dateObj = new Date(aptdate);
      dateObj.setDate(dateObj.getDate() + 1);
      var updatedYear = dateObj.getFullYear();
      var updatedMonth = String(dateObj.getMonth() + 1).padStart(2, "0");
      var updatedDay = String(dateObj.getDate()).padStart(2, "0");
      var updatedDate = `${updatedYear}-${updatedMonth}-${updatedDay}`;
      setAftDate(moment(updatedDate).format("MM-DD-YYYY"));
      setOnDate(moment(updatedDate).format("MM-DD-YYYY"));
      setValidDate1(moment(updatedDate).format("MM-DD-YYYY"));
    }
  }, [aptdate]);

  useEffect(() => {
    axiosInstance
      .get("/vozo/telehealth/getPlatform")
      .then((response) => {
        let decodingResponse = Decrypt_Value(response.data, "vozo");
        setTelehealthplatform(decodingResponse);
      })
      .catch((err) => {
        setTelehealthplatform("zoom");
      });
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleCustomCalendar);

    return () => {
      document.removeEventListener("click", handleCustomCalendar);
    };
  }, []);
  useEffect(() => {
    if (providerid !== "" && providerid !== null && providerid !== undefined) {
      axiosInstance.get("/sprovider?uid=" + providerid).then((response) => {
        const data = Decrypt_Value(response.data, "vozo");
        setAllowAppt(Number(data.appt_type));
        if (Number(data.appt_type) === 3 && Appttype !== 2) {
          setofficeid(props.officeId);
          setAppttype(1);
          setvideochck(false);
          setprofileid("");
          setstatusid("");
          setbillingid("");
          let dur = moment(outTime, "hh:mm a").diff(
            moment(inTime, "hh:mm a"),
            "minutes"
          );
          setEditing(dur);
        } else if (Number(data.appt_type) === 2) {
          setAppttype(2);
          let t1 = inTime.split(" ");
          let t2 = outTime.split(" ");

          let from = disabledFromTimes(t1[0], t1[1], 2);

          if (!from) {
            let intime = moment(start);
            let now = moment();

            if (intime.isAfter(now)) {
              setInTime(moment(start).format("h:mm A"));
              let intime = moment(start).format("h:mm A");
              let out = moment(
                moment(start, "h:mm A").add(15, "minutes")
              ).format("h:mm A");
              setOutTime(out);
              let dur = moment(out, "hh:mm a").diff(
                moment(intime, "hh:mm a"),
                "minutes"
              );
              setEditing(dur);
            }
          } else {
            let now = moment();

            let intime = now.format("h:mm A");

            let checktime = moment(aptdate, "DD-MM-YYYY h:mm a");

            if (checktime.isBefore(now.format("DD-MM-YYYY"))) {
              setInTime("");
              setOutTime("");
              setEditing("");
            } else {
              let out = moment(
                moment(now.format("h:mm A"), "h:mm A").add(15, "minutes")
              ).format("h:mm A");

              setInTime(intime);
              setOutTime(out);
              let dur = moment(out, "hh:mm a").diff(
                moment(now, "hh:mm a"),
                "minutes"
              );
              setEditing(dur);
              setapttime(intime);
            }
          }

          setprofileError(false);
          // setEditing("");
          setstatusid("");
          setbillingid("");
          setprofileid("");

          setvideochck(true);
        } else if (Number(data.appt_type) === 1) {
          setofficeid(props.officeId);
          setAppttype(1);
          setvideochck(false);
          setprofileid("");
          setstatusid("");
          setbillingid("");
        }
      });
    }
  }, [providerid]);

  useEffect(() => {
    const tempIntime = moment(start).format("h:mm A");
    if (props.view === "month") {
      let intime = moment(props.startTime1, "H").format("h:mm A");
      setInTime(intime);

      let outtime = moment(intime, "h:mm A")
        .add(15, "minutes")
        .format("h:mm A");
      setOutTime(outtime);

      let dur = moment(outtime, "h:mm A").diff(
        moment(intime, "h:mm A"),
        "minutes"
      );
      setEditing(dur);
    } else {
      setInTime(moment(start).format("h:mm A"));
      setOutTime(
        moment(moment(tempIntime, "h:mm A").add(15, "minutes")).format("h:mm A")
      );
    }

    const intimeSplit = tempIntime.split(" ");
    const intimeComp = moment(intimeSplit[0], "h:mm A").format("h:mm A");

    // getRoom(props.officeId);
    setaptdate(moment(start).format("MM-DD-YYYY"));
    setLastdateApp(moment(start).format("MM-DD-YYYY"));

    setapttime(moment(start).format("h:mm A"));
    setappfromdate(moment(start).format("YYYY-MM-DD"));
    setapptodate(moment(start).format("YYYY-MM-DD"));
    setproviderid(props.provider);
    setproviderid(
      props.start === undefined ? props.provider : props.start.resourceId
    );

    setofficeid(props.officeId);
    getDatas();
  }, [props.start, props.view]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      let data =
        "pat_share=" +
        storedNames.appt_a +
        "&main_pro=" +
        main_pro +
        "&owner_id=" +
        owner_id +
        "&userID=" +
        userID +
        "&authorized=" +
        authorized +
        "&facilityId=" +
        officeid +
        "&speciality=" +
        speciality +
        "&apttype=" +
        (Appttype == 1
          ? "Appointment"
          : Appttype == 2
          ? "Telehealth"
          : "Appointment");

      let data1 = Encrypt_Value(data, "vozo");

      let url;
      let user = authorized === "1" ? userID : main_pro;

      if (storedNames.appt_a) {
        url = "/vozonew/provider?search=" + data1;
      } else {
        url = "/vozonew/provider?uid=" + user;
      }

      axiosInstance
        .get(url)
        .then((response) => {
          let temp_role_prov = Decrypt_Value(response.data, "vozo");
          setSourceprovider(temp_role_prov);
          if (!storedNames.appt_a || authorized === "0") {
            setNotDisabledSpec(temp_role_prov[0].specialty);
          }
        })
        .catch((err) => console.error(err));
    }
    if (officeid !== "") {
      axiosInstance
        .get("vozo/speciality/getspeciality?officeid=" + officeid)
        .then((res) => {
          let decryptval = Decrypt_Value(res.data, "vozo");
          const countDict = decryptval.reduce((acc, item) => {
            acc[item.specialty] = item.count;
            return acc;
          }, {});

          const matchedSpecialties = specialityList.map((specialty) => ({
            specialty,
            count: countDict[specialty] || 0,
          }));
          matchedSpecialties.sort((a, b) => b.count - a.count);
          setSortSpecility(matchedSpecialties);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [officeid, speciality, Appttype]);

  useEffect(() => {
    axiosInstance
      .get("vozo/getBillingFacility")
      .then((res) => {
        // let decryptval = Decrypt_Value(res.data, "vozo");
        setBillFacility(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    let isCancelled = false;
    const handlechange = async () => {
      await timeout(1000);

      if (!isCancelled) {
        let alle_data =
          "limit=40&fname=" +
          patsrchvalue.toLowerCase() +
          "&authorized=" +
          authorized +
          "&main_pro=" +
          main_pro +
          "&userID=" +
          userID +
          "&pat_share=" +
          storedNames.pat_share +
          "&owner_id=" +
          owner_id +
          "&activepatlist=" +
          1;

        let alle_data_enc = Encrypt_Value(alle_data, "vozo");

        axiosInstance
          .get("/vozo/patientsearch?search=" + alle_data_enc)
          .then((response) => {
            let temp_role = Decrypt_Value(response.data, "vozo");
            setFetchingOptions(false);
            setSource(temp_role); // get 10 datas from response.data main array
            setfornoresults(temp_role);
          })
          .catch(() => {
            setSource([]);
            setfornoresults("");
          });
      }
    };
    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [patsrchvalue]);

  useEffect(() => {
    setBillFacId(bill_facility_id);
    axiosInstance
      .get("vozo/calendarsettingdata/main")
      .then((response) => {
        let temp_role_cal = Decrypt_Value(response.data, "vozo");
        setworkDays(temp_role_cal.cal_drop);
        setStartTime(temp_role_cal.schedule_start);
        setEndTime(temp_role_cal.schedule_end);

        const timeSlots1 = [];
        const timeSlots2 = [];
        let i = 0;

        for (
          let hour = Number(temp_role_cal.schedule_start);
          hour < Number(temp_role_cal.schedule_end);
          hour++
        ) {
          for (let minute = 0; minute < 60; minute += 15) {
            const ampm = hour < 12 ? "AM" : "PM";
            const hourFormatted = hour > 12 ? hour - 12 : hour;
            const time = `${hourFormatted.toString().padStart(2, "0")}:${minute
              .toString()
              .padStart(2, "0")}`;
            timeSlots1.push({ id: ++i, time: time, meridiem: ampm });
          }
        }
        setFromTimeSlots(timeSlots1);
        var frSlotTmId = timeSlots1
          .filter((ele) => {
            return (
              moment(ele.time + ele.meridiem, "HH:mm A").format("HH:mm A") >=
              moment(start).format("HH:mm A")
            );
          })
          .map((ele) => {
            return ele.id;
          });
        setFromId(frSlotTmId.slice(0, 1));
        let j = 0;
        const scheduleStart = Number(temp_role_cal.schedule_start);
        const scheduleEnd = Number(temp_role_cal.schedule_end);

        for (let hour = scheduleStart; hour <= scheduleEnd; hour++) {
          for (let minute = 0; minute < 60; minute += 15) {
            const isPM = hour >= 12;
            const ampm = isPM ? "PM" : "AM";
            let hourFormatted = hour % 12;
            if (hourFormatted === 0) hourFormatted = 12;
            const time = `${hourFormatted.toString().padStart(2, "0")}:${minute
              .toString()
              .padStart(2, "0")}`;

            timeSlots2.push({ id: ++j, time: time, meridiem: ampm });

            if (hour === scheduleEnd && minute === 0) break;
          }
        }
        setToTimeSlots(timeSlots2);

        if (temp_role_cal.schedule_end >= 12) {
          if (temp_role_cal.schedule_end !== "12")
            setEndErrTime(temp_role_cal.schedule_end - 12 + ":00 PM");
          else setEndErrTime("12:00 PM");
        } else {
          setEndErrTime(temp_role_cal.schedule_end + ":00 AM");
        }
        if (temp_role_cal.schedule_start >= 12) {
          if (temp_role_cal.schedule_start !== "12")
            setStartErrTime(temp_role_cal.schedule_start - 12 + ":00 PM");
          else setStartErrTime("12:00 PM");
        } else {
          setStartErrTime(temp_role_cal.schedule_start + ":00 AM");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [bill_facility_id, props.start]);

  useEffect(() => {
    const week_day = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = new Date(moment(aptdate).format("YYYY-MM-DD"));
    setDay(week_day[day.getDay()]);

    setMonth(month[day.getMonth()]);

    setDyOfMont(day.getDate());

    const weekNum = Math.ceil(day.getDate() / 7);
    if (weekNum == 1) {
      setWkOfMont("First");
    } else if (weekNum == 2) {
      setWkOfMont("Second");
    } else if (weekNum == 3) {
      setWkOfMont("Third");
    } else if (weekNum == 4) {
      setWkOfMont("Fourth");
    } else {
      setWkOfMont("Fifth");
    }
  }, [aptdate]);

  // useEffect(() => {
  //   axiosInstance
  //     .get("vozo/speciality/getspeciality?officeid=" + officeid)
  //     .then((res) => {
  //       let decryptval = Decrypt_Value(res.data, "vozo");
  //       if (decryptval.length !== 0) {
  //         const countDict = decryptval.reduce((acc, item) => {
  //           acc[item.specialty] = item.count;
  //           return acc;
  //         }, {});

  //         const matchedSpecialties = specialityList.map((specialty) => ({
  //           specialty,
  //           count: countDict[specialty] || 0,
  //         }));
  //         matchedSpecialties.sort((a, b) => b.count - a.count);
  //         setSortSpecility(matchedSpecialties);
  //       } else {
  //         setSortSpecility("");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [officeid]);

  // nisha start //

  const [inTime, setInTime] = useState("");
  const [outTime, setOutTime] = useState("");
  const [fromShow, setFromShow] = useState(false);
  const [toShow, setToShow] = useState(false);
  const [fromId, setFromId] = useState("");
  const [toId, setToId] = useState("");

  const fromTime = [
    { id: 1, time: "9:00 ", meridiem: "AM" },
    { id: 2, time: "9:15 ", meridiem: "AM" },
    { id: 3, time: "9:30 ", meridiem: "AM" },
    { id: 4, time: "9:45 ", meridiem: "AM" },
    { id: 5, time: "10:00 ", meridiem: "AM" },
    { id: 6, time: "10:15 ", meridiem: "AM" },
    { id: 7, time: "10:30 ", meridiem: "AM" },
    { id: 8, time: "10:45 ", meridiem: "AM" },
    { id: 9, time: "11:00 ", meridiem: "AM" },
    { id: 10, time: "11:15 ", meridiem: "AM" },
    { id: 11, time: "11:30 ", meridiem: "AM" },
    { id: 12, time: "11:45 ", meridiem: "AM" },
    { id: 13, time: "12:00 ", meridiem: "PM" },
    { id: 14, time: "12:15 ", meridiem: "PM" },
    { id: 15, time: "12:30 ", meridiem: "PM" },
    { id: 16, time: "12:45 ", meridiem: "PM" },
    { id: 17, time: "01:00 ", meridiem: "PM" },
    { id: 18, time: "01:15 ", meridiem: "PM" },
    { id: 19, time: "01:30 ", meridiem: "PM" },
    { id: 20, time: "01:45 ", meridiem: "PM" },
    { id: 21, time: "02:00 ", meridiem: "PM" },
    { id: 22, time: "02:15 ", meridiem: "PM" },
    { id: 23, time: "02:30 ", meridiem: "PM" },
    { id: 24, time: "02:45 ", meridiem: "PM" },
    { id: 25, time: "03:00 ", meridiem: "PM" },
    { id: 26, time: "03:15 ", meridiem: "PM" },
    { id: 27, time: "03:30 ", meridiem: "PM" },
    { id: 28, time: "03:45 ", meridiem: "PM" },
    { id: 29, time: "04:00 ", meridiem: "PM" },
    { id: 30, time: "04:15 ", meridiem: "PM" },
    { id: 31, time: "04:30 ", meridiem: "PM" },
    { id: 32, time: "04:45 ", meridiem: "PM" },
    // { id: 33, time: "05:00 ", meridiem: "PM" },
  ];
  const toTime = [
    // { id: 1, time: "9:00 ", meridiem: "AM" },
    { id: 2, time: "9:15 ", meridiem: "AM" },
    { id: 3, time: "9:30 ", meridiem: "AM" },
    { id: 4, time: "9:45 ", meridiem: "AM" },
    { id: 5, time: "10:00 ", meridiem: "AM" },
    { id: 6, time: "10:15 ", meridiem: "AM" },
    { id: 7, time: "10:30 ", meridiem: "AM" },
    { id: 8, time: "10:45 ", meridiem: "AM" },
    { id: 9, time: "11:00 ", meridiem: "AM" },
    { id: 10, time: "11:15 ", meridiem: "AM" },
    { id: 11, time: "11:30 ", meridiem: "AM" },
    { id: 12, time: "11:45 ", meridiem: "AM" },
    { id: 13, time: "12:00 ", meridiem: "PM" },
    { id: 14, time: "12:15 ", meridiem: "PM" },
    { id: 15, time: "12:30 ", meridiem: "PM" },
    { id: 16, time: "12:45 ", meridiem: "PM" },
    { id: 17, time: "01:00 ", meridiem: "PM" },
    { id: 18, time: "01:15 ", meridiem: "PM" },
    { id: 19, time: "01:30 ", meridiem: "PM" },
    { id: 20, time: "01:45 ", meridiem: "PM" },
    { id: 21, time: "02:00 ", meridiem: "PM" },
    { id: 22, time: "02:15 ", meridiem: "PM" },
    { id: 23, time: "02:30 ", meridiem: "PM" },
    { id: 24, time: "02:45 ", meridiem: "PM" },
    { id: 25, time: "03:00 ", meridiem: "PM" },
    { id: 26, time: "03:15 ", meridiem: "PM" },
    { id: 27, time: "03:30 ", meridiem: "PM" },
    { id: 28, time: "03:45 ", meridiem: "PM" },
    { id: 29, time: "04:00 ", meridiem: "PM" },
    { id: 30, time: "04:15 ", meridiem: "PM" },
    { id: 31, time: "04:30 ", meridiem: "PM" },
    { id: 32, time: "04:45 ", meridiem: "PM" },
    { id: 33, time: "05:00 ", meridiem: "PM" },
  ];

  // useEffect(() => {
  //   console.log("Intime is " + inTime);
  //   console.log("Outtime is " + outTime);
  // }, [inTime, outTime, fromId, toId]);

  const handleFromTime = () => {
    if (fromShow === false) {
      setFromShow(true);
      setToShow(false);
      setTempOpen(true);
    } else {
      setFromShow(false);
    }
  };
  const handleToTime = () => {
    if (toShow === false) {
      setToShow(true);
      setFromShow(false);
      setTempOpen(true);
    } else {
      setToShow(false);
    }
  };
  const handleFromButton = (e, index) => {
    e.preventDefault();
    if (fromShow === true || toShow === true) {
      setFromShow(false);
      setToShow(false);
    }
  };
  const handleToButton = (e, index) => {
    e.preventDefault();
    if (fromShow === true || toShow === true) {
      setFromShow(false);
      setToShow(false);
    }
  };

  // nisha end //

  const customRadio = (e) => {
    setcustchkerr(false);
    if (e.target.value === 1) {
      setdateselect2new("Never");
      setcustChk(1);
    } else if (e.target.value === 2) {
      setdateselect2new("On");
      setcustChk(2);
    } else if (e.target.value === 3) {
      setdateselect2new("After");
      setcustChk(3);
    }
  };

  window.onclick = function (e) {
    if (fromShow && tempOpen === false) {
      setFromShow(false);
    } else if (toShow && tempOpen === false) {
      setToShow(false);
    }
  };
  var regexFirstNum = /^[a-zA-Z].*/;

  // ------------------------------ useEffect----end---------------------------------------------------- //

  return (
    <>
      <Modal.Title className="New-App_Mod-Title">New Appointment</Modal.Title>

      <Modal.Body className="c-pop New-App_Mod-Body" id="new-appts">
        {SpinnerVal ? (
          <SpinnerDiv height="432px">
            <Spinner animation="border" variant="primary" />
          </SpinnerDiv>
        ) : (
          <>
            <Div className="c-pop-one">
              <Form.Row className="app-block  antd-select-height1">
                <Div alignItems="center" className="search-pat p-r-20 wholediv">
                  <Div margin="-20px 0px 0px -2px">
                    <PeopleOutline className="people" />
                  </Div>
                  <Div className="dropdiv" id="PatId">
                    <Select
                      showSearch
                      placeholder="Select Patient"
                      id="new-app-patient_1 appt-inp-patsrch"
                      suffixIcon={<BsFillCaretDownFill />}
                      className={patError ? "err_mess_border w-100 " : "w-100"}
                      onSearch={(value) => {
                        setpatsrchvalue(value);
                        setFetchingOptions(true);
                      }}
                      onChange={(e, data) =>
                        handleDropDownSelectPatient(e, data)
                      }
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.title ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      notFoundContent={
                        fetchingOptions ? "Loading..." : "No Data found"
                      }
                      value={patientid ? patientid : undefined}
                      getPopupContainer={() => document.getElementById("PatId")}
                    >
                      {source.map((i) => (
                        <Option key={i.value} value={i.value} title={i.text}>
                          {i.text}
                        </Option>
                      ))}
                    </Select>
                    {patError && (
                      <p className="errormessage">Please select Patient</p>
                    )}
                  </Div>
                </Div>
                <Div style={{ display: "flex", margin: "-19px 0 4px 29px" }}>
                  <Div className="access_icon">
                    <AccessTimeIcon className="people" />
                  </Div>
                  <form
                    className={`${classes.container} frm-apoint`}
                    noValidate
                  >
                    <div
                      id="crt_appt-time-ok"
                      className="date-pick date-pick-parent"
                    >
                      {/* <DatePicker
                        className="date-picker-width"
                        defaultValue={moment(start, dateFormat)}
                        disabledDate={disabledDate}
                        format={dateFormat}
                        onChange={(e) => {
                          fdate(e);
                        }}
                      /> */}
                      <CustomDatePicker
                        id="CustomDatePicker"
                        width="120px"
                        height="36px"
                        backgroundColour="#f7f7f7 !important"
                        border="none"
                        placeholder="MM-DD-YYYY"
                        value={aptdate} //used in application
                        onChange={(e) => {
                          let d = moment(
                            e.target.value,
                            "MM-DD-YYYY",
                            true
                          ).isValid();
                          setaptdate(e.target.value);

                          if (e.target.value === "") {
                            setValidDate("");
                          } else if (d) {
                            let check = false;
                            check =
                              workDays === 1
                                ? new Date(e.target.value).getDay() === 0
                                  ? true
                                  : false
                                : workDays === 2
                                ? new Date(e.target.value).getDay() === 0 ||
                                  new Date(e.target.value).getDay() === 6
                                  ? true
                                  : false
                                : false;
                            if (check) {
                              if (aptdate !== "") {
                                setaptdate(
                                  moment(aptdate).format("MM-DD-YYYY")
                                );
                              }
                            } else {
                              setValidDate(e.target.value);
                            }
                          }
                        }}
                        onClick={(e) => handlepicker(e)}
                        onKeyPress={(event) => {
                          if (
                            !/[0-9]/.test(event.key) ||
                            event.target.value.length > 9
                          ) {
                            event.preventDefault();
                          }
                        }}
                        onKeyDown={(e) => keyDownExpiry(e)}
                        onResultSelect={dateSelect} // state updation for picking calendar dates
                        showPicker={showpicker}
                        validDate={aptdate} // value in date object for calendar manipulation
                        closePicker={closePicker}
                        disabledDate={workDays}
                        min={
                          Appttype === 2 ? moment().format("YYYY-MM-DD") : ""
                        }
                      />

                      {/* <TimePicker
                        className={
                          timelimiterrorbox ? "timepick just-err" : "timepick"
                        }
                        defaultValue={moment(start, "h:mm A")}
                        use12Hours
                        format="h:mm A"
                        onChange={onChange}
                        id="new-set-time"
                        disabled={alldaydisable}
                      /> */}
                      {/* <span className="date_space">-</span>
                      <TimePicker
                        className={
                          timelimiterrorbox ? "timepick just-err" : "timepick"
                        }
                        defaultValue={moment(start, "h:mm A")}
                        use12Hours
                        format="h:mm A"
                        onChange={onChange}
                        id="new-set-time"
                        // disabled={alldaydisable}
                      />
                      <input
                        className="we-none12 "
                        id="new-app-duration"
                        value={editing}
                        onChange={handleHoursInput}
                        // disabled={alldaydisable}
                      ></input>
                      Min */}
                      {/* nisha start */}
                      <div className="App-time">
                        <div className="showTime">
                          <input
                            className="fromTime-toTime"
                            onClick={() => {
                              handleFromTime();
                            }}
                            onMouseLeave={() => {
                              setTempOpen(false);
                            }}
                            value={inTime}
                            onChange={(e) => {
                              if (!regexFirstNum.test(e.target.value)) {
                                setFromShow(false);
                                setToShow(false);
                                setInTime(e.target.value);
                                setTimeout(() => {
                                  setInTime(
                                    moment(e.target.value, "h:mm A").format(
                                      "h:mm A"
                                    )
                                  );
                                  onChange(
                                    moment(e.target.value, "h:mm A").format(
                                      "h:mm A"
                                    ),
                                    "inTime"
                                  );
                                }, 2000);
                              }
                            }}
                            onBlur={(e) => {
                              setInTime(
                                moment(e.target.value, "h:mm A").format(
                                  "h:mm A"
                                )
                              );
                              onChange(
                                moment(e.target.value, "h:mm A").format(
                                  "h:mm A"
                                ),
                                "inTime"
                              );
                            }}
                          />
                          <p className="time--space">-</p>
                          <input
                            className="fromTime-toTime"
                            onClick={() => {
                              handleToTime();
                            }}
                            onChange={(e) => {
                              if (!regexFirstNum.test(e.target.value)) {
                                setFromShow(false);
                                setToShow(false);
                                setOutTime(e.target.value);
                                setTimeout(() => {
                                  setOutTime(
                                    moment(e.target.value, "h:mm A").format(
                                      "h:mm A"
                                    )
                                  );
                                  onChange(
                                    moment(e.target.value, "h:mm A").format(
                                      "h:mm A"
                                    ),
                                    "outTime"
                                  );
                                }, 2000);
                              }
                            }}
                            onMouseLeave={() => {
                              setTempOpen(false);
                            }}
                            onBlur={(e) => {
                              setOutTime(
                                moment(e.target.value, "h:mm A").format(
                                  "h:mm A"
                                )
                              );
                              onChange(
                                moment(e.target.value, "h:mm A").format(
                                  "h:mm A"
                                ),
                                "outTime"
                              );
                            }}
                            value={outTime}
                          />
                          {/* {outTime}
                          </p> */}
                        </div>

                        {/* From Button */}
                        <div
                          className={
                            fromShow ? "fromArray-toArray" : "fromArray_hide"
                          }
                        >
                          {frtimeSlots.map((item, index) => {
                            return (
                              <button
                                // disabled={toId >= item.id ? true : false}
                                key={item.id}
                                value={item.time + " " + item.meridiem}
                                disabled={disabledFromTimes(
                                  item.time,
                                  item.meridiem,
                                  Appttype
                                )}
                                onClick={async (e) => {
                                  setInTime(
                                    moment(e.target.value, "h:mm A").format(
                                      "h:mm A"
                                    )
                                  );
                                  await setFromId(item.id);
                                  handleFromButton(e);
                                  onChange(
                                    moment(e.target.value, "h:mm A").format(
                                      "h:mm A"
                                    ),
                                    "inTime"
                                  );
                                }}
                              >
                                {item.time + " " + item.meridiem}
                              </button>
                            );
                          })}
                        </div>
                        {/* To button */}
                        <div
                          className={
                            toShow ? "fromArray-toArray" : "toArray_hide"
                          }
                          style={{ left: 70 }}
                        >
                          {totimeSlots.map((item, index) => {
                            return (
                              <button
                                disabled={disabledToTime(
                                  item.time,
                                  item.meridiem,
                                  item.id,
                                  Appttype
                                )}
                                key={index}
                                value={item.time + " " + item.meridiem}
                                onClick={async (e) => {
                                  setOutTime(
                                    moment(e.target.value, "h:mm A").format(
                                      "h:mm A"
                                    )
                                  );
                                  await setToId(item.id);
                                  handleToButton(e);
                                  onChange(
                                    moment(e.target.value, "h:mm A").format(
                                      "h:mm A"
                                    ),
                                    "outTime"
                                  );
                                }}
                              >
                                {item.time + " " + item.meridiem}
                              </button>
                            );
                          })}
                        </div>
                      </div>
                      {/* nisha end */}
                    </div>
                  </form>
                </Div>
                {inTimeErr && (
                  <Form.Text
                    id="new-app-time-codetext appt-warn-time"
                    className="colour-red p-l-66 m-t-0-imp m-b-3"
                  >
                    Please Select valid Time
                  </Form.Text>
                )}
                {timelimiterrorbox && (
                  <Form.Text
                    id="new-app-time-codetext appt-warn-time"
                    className="colour-red p-l-66 m-t-0-imp m-b-3"
                  >
                    Provider Only Available Between {startErrTime} to{" "}
                    {endErrTime}
                  </Form.Text>
                )}

                <Div alignItems="center" className="appointment_popup_div">
                  <Div style={{ textAlign: "left" }}>
                    <Popup
                      content={fornormalapp}
                      open={normalappointment}
                      trigger={
                        <Button id="click_event_app_normal" content="Button" />
                      }
                      position="left center"
                    />
                  </Div>
                  <Div>
                    <Popup
                      content={forteleapp}
                      open={teleappointment}
                      trigger={
                        <Button id="click_event_app_tele" content="Button" />
                      }
                      position="left center"
                    />
                  </Div>
                  <Div id="new_appt_radio" className="appt-spn-vdovis">
                    <Radio.Group
                      onChange={
                        accountType === "-1" ||
                        (accountType === "1" && teleFlag === "0")
                          ? (e) => {
                              setUpgrade(true);
                            }
                          : onChangeRadio
                      }
                      value={forteleornor === 2 ? forteleornor : Appttype}
                    >
                      <Radio
                        className="radio_appt_btn"
                        value={1}
                        onChange={checkapt}
                        disabled={allowAppt === 2}
                      >
                        Appointment
                      </Radio>
                      {/* {(accountType === "0" && trial) ||
                      (accountType === "1" && teleFlag === "1") ||
                      accountType > "1" ? ( */}
                      <Radio
                        value={2}
                        onChange={checkvideovisit}
                        disabled={allowAppt === 1}
                      >
                        Video Visit
                      </Radio>
                      {/* ) : (
                        <Radio value={2} disabled onChange={checkvideovisit}>
                          Video Visit
                        </Radio>
                      )} */}
                      <Checkbox
                        onClick={walkinclick}
                        disabled={videochck}
                        id="new-app-walkin"
                      >
                        Walk-in
                      </Checkbox>
                    </Radio.Group>
                  </Div>
                </Div>
                {/* <FormGroup row>
                  <FormControlLabel
                    className="all-day1"
                    control={
                      <Checkbox
                        id="new-app-all-day"
                        onClick={alldaycheck}
                        name="checkedB"
                        color="primary"
                      />
                    }
                  />
                  <span className="event-all">All Day Event</span>
                </FormGroup> */}
                <Div
                  className="space-border1 w-100"
                  id="border1"
                  onClick={changeborder}
                  margin="15px 0 0 0"
                >
                  <Div
                    alignItems="center"
                    // className="alx p-r-20"
                    className=" alx p-r-20 wholediv"
                  >
                    <Div margin="-20px 0px 0px -2px">
                      <RoomOutlined />
                    </Div>
                    <Div className="dropdiv" id="officeId">
                      <Select
                        showSearch
                        placeholder="Select Office"
                        id="new-app-facility_1"
                        suffixIcon={<BsFillCaretDownFill />}
                        className={
                          officeError
                            ? `err_mess_border w-100 to-hide-ant-label ${checkFacility(
                                "classname"
                              )}`
                            : `to-hide-ant-label w-100 ${checkFacility(
                                "classname"
                              )}`
                        }
                        onChange={(e, data) =>
                          handleDropDownSelectOffice(e, data)
                        }
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.title ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        value={officeid ? officeid : undefined}
                        getPopupContainer={() =>
                          document.getElementById("officeId")
                        }
                      >
                        {sourceoffice.map((i) => (
                          <Option key={i.value} value={i.value} title={i.text}>
                            {i.text}
                          </Option>
                        ))}
                      </Select>
                      {checkFacility("spinner")}
                      {officeError && (
                        <p className="errormessage">Please select Office</p>
                      )}
                    </Div>
                  </Div>
                </Div>

                <Div className="space-border1 w-100" margin="0">
                  <Div alignItems="center" className=" alx p-r-20 wholediv">
                    <Div margin="-20px 0px 0px -2px">
                      <Image
                        src={doctorLogo}
                        alt="speciality"
                        height="20px"
                        width="20px"
                      />
                    </Div>
                    <Div className="dropdiv">
                      <Select
                        showSearch
                        placeholder="Select Speciality"
                        suffixIcon={<BsFillCaretDownFill />}
                        className={
                          specialError ? "err_mess_border w-100" : "w-100"
                        }
                        onChange={(data) => changeSpeciality(data)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.title ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        value={speciality ? speciality : undefined}
                        optionLabelProp="label"
                        dropdownClassName="speciality-option"
                      >
                        {sortSpeciality.length !== 0 &&
                          sortSpeciality.map((item) => (
                            <Option
                              key={item.specialty}
                              value={item.specialty}
                              title={item.specialty}
                              disabled={
                                !storedNames.appt_a
                                  ? item.specialty !== notDisabledSpec
                                  : item.count === 0
                              }
                              id={item.count === 0 ? "clr-disable" : ""}
                              className={
                                !storedNames.appt_a
                                  ? item.speciality !== notDisabledSpec
                                    ? "custom-disabled-option"
                                    : ""
                                  : item.count === 0
                                  ? "custom-disabled-option"
                                  : ""
                              }
                            >
                              {item.speciality}
                            </Option>
                          ))}
                      </Select>
                      {specialError && (
                        <p className="errormessage">Select a speciality</p>
                      )}
                    </Div>
                  </Div>
                </Div>

                <Div
                  alignItems="center"
                  className="search-pat p-r-20 appointment_patient_div"
                >
                  <Div margin="-2px 0px 0px -2px">
                    <PeopleOutline />
                  </Div>
                  <Div className="appointment_drop_div" id="provId">
                    <Select
                      showSearch
                      placeholder="Select Provider"
                      suffixIcon={<BsFillCaretDownFill />}
                      id="new-app-provider new-app-provider_1"
                      className={provError ? "err_mess_border w-100 " : "w-100"}
                      onChange={(e, data) =>
                        handleDropDownSelectProvider(e, data)
                      }
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.title ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      value={providerid ? providerid : undefined}
                      getPopupContainer={() =>
                        document.getElementById("provId")
                      }
                    >
                      {sourceprovider.map((i) => (
                        <Option key={i.value} value={i.value} title={i.text}>
                          {i.text}
                        </Option>
                      ))}
                    </Select>
                    {provError && (
                      <p className="errormessage">Please select Provider</p>
                    )}
                  </Div>
                </Div>

                {/* <Div margin=" 0px 0px 17px 20px">
                  <Div style={{ display: "flex" }}>
                    <Div className="all-day1">
                      <Checkbox
                        onClick={check1}
                        name="name6"
                        color="primary"
                        id="new-app-repeats-check1"
                        checked={checking1}
                      />
                    </Div>
                    <span className="app_rep">Repeats</span>
                  </Div>
                  {status ? (
                    <Div
                      margin="0 0 0 63px"

                      // className="marg pl-9"
                    >
                      <Div margin=" 0px 0px 0px -5px">
                        {everydayError && (
                          <p className="errormessage ">Please select One</p>
                        )}
                        <Div className="repeats m-l-0">
                          <Form.Control
                            as="select"
                            className={
                              everydayError
                                ? "err_mess_border drop-svg we-none"
                                : "drop-svg we-none"
                            }
                            id="new-app-select1"
                            value={repeatselectnew}
                            onChange={(e) => {
                              handleSelect2("new-app-select1", e.target.value);
                            }}
                            disabled={swict_drop}
                          >
                            <option value="">Select</option>
                            <option value="1">every</option>
                            <option value="2">2nd</option>
                            <option value="3">3rd</option>
                            <option value="4">4th</option>
                            <option value="5">5th</option>
                            <option value="6">6th</option>
                          </Form.Control>

                          <Form.Control
                            as="select"
                            className="drop-svg we-none m-l-20 "
                            id="new-app-select2"
                            value={repeatselect2new}
                            onChange={(e) =>
                              handleSelect2("new-app-select2", e.target.value)
                            }
                            disabled={swict_drop}
                          >
                            <option value="0">day</option>
                            <option value="4">workday</option>
                            <option value="1">week</option>
                            <option value="2">month</option>
                            <option value="3">year</option>
                          </Form.Control>
                        </Div>
                      </Div>
                      <Div className="switch-app">
                        <Switch
                          checkedChildren=""
                          unCheckedChildren="Days wise"
                          id="new-app-days-wise"
                          checked={dayswise}
                          onChange={days_check}
                        />
                      </Div>

                      <ToggleButtonGroup
                        type="checkbox"
                        value={weekday}
                        className={
                          selectdayError
                            ? "err_mess_border appt_repeats but-act m-l-neg6 "
                            : "appt_repeats but-act m-l-neg6"
                        }
                        onChange={WeekdayChange}
                      >
                        <ToggleButton
                          id="tbg-btn-1"
                          value={1}
                          disabled={swict_check}
                        >
                          S
                        </ToggleButton>
                        <ToggleButton
                          id="tbg-btn-2"
                          value={2}
                          disabled={swict_check}
                        >
                          M
                        </ToggleButton>
                        <ToggleButton
                          id="tbg-btn-3"
                          value={3}
                          disabled={swict_check}
                        >
                          T
                        </ToggleButton>
                        <ToggleButton
                          id="tbg-btn-3"
                          value={4}
                          disabled={swict_check}
                        >
                          W
                        </ToggleButton>
                        <ToggleButton
                          id="tbg-btn-3"
                          value={5}
                          disabled={swict_check}
                        >
                          T
                        </ToggleButton>
                        <ToggleButton
                          id="tbg-btn-3"
                          value={6}
                          disabled={swict_check}
                        >
                          F
                        </ToggleButton>
                        <ToggleButton
                          id="tbg-btn-3"
                          value={7}
                          disabled={swict_check}
                        >
                          S
                        </ToggleButton>
                      </ToggleButtonGroup>
                      {selectdayError && (
                        <p className="errormessage m-l-neg6">
                          Please select Day
                        </p>
                      )}
                      <Div className=" rep-date">
                        Untildate
                        <DatePicker
                          className={
                            untildateError ? "err_mess_border w-100 " : "w-100"
                          }
                          placeholder="Until date"
                          id="new-app-untildate"
                          onChange={(e) => {
                            handleUntilDate(e);
                          }}
                          disabledDate={(current) => current.isBefore(aptdate)}
                        />
                      </Div>

                      {untildateError && (
                        <p className="errormessage m-l-neg6 ">
                          Please select Until date
                        </p>
                      )}
                    </Div>
                  ) : null}
                </Div> */}

                <Div
                  className="app_repeat_dropdown"
                  margin=" 0px 0px 16px 68px"
                >
                  <BsFillCaretDownFill className="down_arrow_doesnt" />
                  <DropdownButton
                    id="arrow-newAppt"
                    className="app-nav-dropdown"
                    title={repvalue ? repvalue : "Does not repeat "}
                    onSelect={handlerepSelect}
                    variant="light"
                  >
                    <Dropdownant.Item
                      onClick={handleShowuntildate1}
                      eventKey="Does not repeat"
                    >
                      Does not repeat
                    </Dropdownant.Item>
                    <Dropdownant.Item
                      onClick={handleShowuntildate}
                      eventKey="Daily"
                    >
                      Daily
                    </Dropdownant.Item>
                    <Dropdownant.Item
                      onClick={handleShowuntildate}
                      eventKey={`Weekly on ${day}`}
                    >
                      Weekly on {day}
                    </Dropdownant.Item>
                    <Dropdownant.Item
                      onClick={handleShowuntildate}
                      eventKey={`Monthly on the ${wkOfMont} ${day}`}
                    >
                      Monthly on the {wkOfMont} {day}
                    </Dropdownant.Item>
                    <Dropdownant.Item
                      onClick={handleShowuntildate}
                      eventKey="Every Weekday(Monday to Friday)"
                    >
                      Every Weekday(Monday to Friday)
                    </Dropdownant.Item>

                    <Dropdownant.Item
                      eventKey="Custom"
                      onClick={handleShowrepApp}
                    >
                      Custom...
                      <BsFillCaretDownFill className="down_custom_txt" />
                    </Dropdownant.Item>
                  </DropdownButton>
                </Div>
                {lastdate && (
                  <Div margin=" 0px 0px 16px 68px" dis="flex">
                    <Span marginRight="3px" color="#000000 !important">
                      Until:
                    </Span>
                    <Div position="relative">
                      <CustomDatePicker
                        id="CustomDatePicker"
                        width="121px"
                        height="37px"
                        backgroundColour="#f7f7f7 !important"
                        border="1px solid rgba(34,36,38,.15)"
                        placeholder="MM-DD-YYYY"
                        value={lastdateApp}
                        onChange={(e) => {
                          let d = moment(
                            e.target.value,
                            "MM-DD-YYYY",
                            true
                          ).isValid();

                          setLastdateApp(
                            moment(e.target.value).format("MM-DD-YYYY")
                          );
                          if (e.target.value === "") {
                            setValidDate3("");
                          } else if (d) {
                            let check = false;
                            check = aptdate
                              ? moment(e.target.value)
                                ? true
                                : false
                              : false;

                            if (check) {
                              if (lastdateApp !== "") {
                                setLastdateApp(
                                  moment(lastdateApp).format("MM-DD-YYYY")
                                );
                              }
                            } else {
                              setValidDate3(
                                moment(e.target.value).format("MM-DD-YYYY")
                              );
                            }
                          }
                        }}
                        onClick={(e) => handlepicker3(e)}
                        onKeyPress={(event) => {
                          if (
                            !/[0-9]/.test(event.key) ||
                            event.target.value.length > 9
                          ) {
                            event.preventDefault();
                          }
                        }}
                        onKeyDown={(e) => keyDownExpiry(e)}
                        onResultSelect={dateSelect3} // state updation for picking calendar dates
                        showPicker={showpicker3}
                        validDate={lastdateApp} // value in date object for calendar manipulation
                        closePicker={closePicker3}
                        min={aptdate}
                        max={maxDate}
                      />
                      <Imagecalendar
                        top="10px"
                        right="5px"
                        id="CustomDatePicker"
                        onClick={(e) => handlepicker3(e)}
                        src={calendar_icon_custom}
                      ></Imagecalendar>
                    </Div>
                  </Div>
                )}
                <hr id="hide" />

                <Div className="space-border w-100" id="border">
                  <Div
                    className="wholediv "

                    // className="alx p-r-20"
                  >
                    <Div margin="-20px 0px 0px -2px">
                      <Image
                        src={BillingFacilityIcon}
                        alt="bill"
                        height="20px"
                        width="20px"
                      />
                    </Div>

                    <Div className="dropdiv" id="billingFac">
                      <Select
                        showSearch
                        placeholder="Billing Facility"
                        suffixIcon={<BsFillCaretDownFill />}
                        id="new-app-status_1 appt-inp-stas"
                        className={
                          billFacilityError ? "err_mess_border w-100" : "w-100"
                        }
                        onChange={(e, data) => handleBillingFacility(e, data)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.title ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        value={billFacilityID ? billFacilityID : undefined}
                        getPopupContainer={() =>
                          document.getElementById("billingFac")
                        }
                      >
                        {billFacility?.map((i) => (
                          <Option key={i.value} value={i.value} title={i.text}>
                            {i.text}
                          </Option>
                        ))}
                      </Select>
                      {billFacilityError && (
                        <p className="errormessage">
                          Please Select the Facility
                        </p>
                      )}
                    </Div>
                  </Div>

                  <Div
                    className="wholediv"
                    // className="alx p-r-20"
                  >
                    <Div margin="-20px 0px 0px -2px">
                      <LocalHospitalOutlined />
                    </Div>
                    <Div
                      className={videochck ? "dropdiv downDropDown" : "dropdiv"}
                      id="statId"
                    >
                      <Select
                        showSearch
                        placeholder="Select Status"
                        id="new-app-status_1 appt-inp-stas"
                        suffixIcon={<BsFillCaretDownFill />}
                        onClick={changeborder}
                        // className="form-control "
                        className={
                          statusError ? "err_mess_border w-100" : "w-100"
                        }
                        onChange={(e, data) =>
                          handleDropDownSelectStatus(e, data)
                        }
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.title ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        value={statusid ? statusid : undefined}
                        getPopupContainer={() =>
                          document.getElementById("statId")
                        }
                      >
                        {checking1 ? (
                          <></>
                        ) : (
                          <>
                            {videochck ? (
                              <>
                                {sourcestatustele.map((i) => (
                                  <Option
                                    key={i.value}
                                    value={i.value}
                                    title={i.text}
                                  >
                                    {i.text}
                                  </Option>
                                ))}
                              </>
                            ) : (
                              <>
                                {sourcestatus.map((i) => (
                                  <Option
                                    key={i.value}
                                    value={i.value}
                                    title={i.text}
                                  >
                                    {i.text}
                                  </Option>
                                ))}
                              </>
                            )}
                          </>
                        )}
                      </Select>
                      {statusError && (
                        <p className="errormessage">Please select Status</p>
                      )}
                    </Div>
                  </Div>
                </Div>
              </Form.Row>
            </Div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="client-app new_appt_btn_alt">
        <Button
          className="close_active close_active2 "
          variant="light"
          onClick={props.closepopup}
        >
          Cancel
        </Button>
        <Button
          id="appt-btn-sve"
          className="save_active save-btn"
          variant="primary"
          type="submit"
          onClick={buttonClickActions}
          disabled={btnDis}
        >
          Save
        </Button>
      </Modal.Footer>

      <Modal
        className="newModal app_custom"
        show={showrepApp}
        onHide={handleCloserepApp}
        backdrop="static"
      >
        <Modal.Title className="New-App_Mod-Title1">
          Custom recurrence
        </Modal.Title>
        <Modal.Body>
          <Div
            size="14px"
            margin="-13px 0px 16px 11px"
            className="repeat_every_app"
          >
            Every:
            <Select
              suffixIcon={<CustomSuffixIcon />}
              value={repeatselectnew ? repeatselectnew : ""}
              className="repeat-form-control repeat-antd"
              onChange={(e) => {
                handleSelect2("new-app-select1", e);
              }}
              // disabled={swict_drop}
            >
              <Option value="">Select</Option>
              <Option value="1">1</Option>
              {/* <Option value="1">every</Option> */}
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="5">5</Option>
              <Option value="6">6</Option>
            </Select>
            <Select
              className="form-control form-select repeat-antd-day"
              value={repeatselect2new ? repeatselect2new : undefined}
              suffixIcon={<CustomSuffixIcon />}
              onChange={(e) => handleSelect2("new-app-select2", e)}
              // disabled={swict_drop}
            >
              <Option value="0">day</Option>
              {/* <Option value="4">workday</Option> */}
              <Option value="1">week </Option>
              <Option value="2">month</Option>
              <Option value="3">year</Option>
            </Select>
          </Div>
          {everydayError && (
            <p className="errormessage m-l-10 m-t--10 ">
              Please select repeat Option
            </p>
          )}
          <Div size="14px" margin="-10px 0px 23px 11px">
            {swict_check ? (
              <>
                <p>Repeats on:</p>
                <Div margin="15px 4px 14px 0px">
                  <ToggleButtonGroup
                    type="checkbox"
                    value={weekday}
                    className="appt_repeats but-act"
                    onChange={WeekdayChange}
                    style={
                      selectdayError
                        ? { border: "1px solid red", borderRadius: "5px" }
                        : {}
                    }
                  >
                    <ToggleButton id="tbg-btn-1" value={1}>
                      S
                    </ToggleButton>
                    <ToggleButton id="tbg-btn-2" value={2}>
                      M
                    </ToggleButton>
                    <ToggleButton id="tbg-btn-3" value={3}>
                      T
                    </ToggleButton>
                    <ToggleButton id="tbg-btn-3" value={4}>
                      W
                    </ToggleButton>
                    <ToggleButton id="tbg-btn-3" value={5}>
                      T
                    </ToggleButton>
                    <ToggleButton id="tbg-btn-3" value={6}>
                      F
                    </ToggleButton>
                    <ToggleButton id="tbg-btn-3" value={7}>
                      S
                    </ToggleButton>
                  </ToggleButtonGroup>

                  {selectdayError && (
                    <p className="errormessage">Please select atleast a day</p>
                  )}
                </Div>
              </>
            ) : (
              <></>
            )}
          </Div>

          <Div size="14px" margin="-10px 0px 23px 11px">
            {swict_check_mnth ? (
              <>
                <Div margin="15px 4px 37px 0px" width="172px">
                  <Select
                    suffixIcon={<CustomSuffixIcon />}
                    className="arrow-newApptadjust w-100"
                    onChange={(e) => setmonthDropdown(e)}
                    value={repeatselectmonth ? repeatselectmonth : "1"}
                    placeholder="Select"
                    defaultValue="1"
                    // disabled={swict_drop}
                  >
                    <Option value="1">Monthly on day {dyOfMont}</Option>
                    <Option value="2">
                      Monthly on the {wkOfMont} {day}
                    </Option>
                  </Select>
                </Div>
              </>
            ) : (
              <></>
            )}
          </Div>

          <Div size="14px" margin="-13px 0px 16px 11px">
            <p>Ends</p>
            <Radio.Group onChange={customRadio} value={custChk}>
              <Radio className="radio_appt_btn1 sty-c-2e2e2e" value={1}>
                Never
              </Radio>
              <Div
                margin="12px 0 0 0"
                className="rep-date1"
                dis="flex"
                justifyContent="space-between"
                padding="0px 42px 0px 0px"
              >
                <Radio className="radio_appt_btn1 sty-c-2e2e2e" value={2}>
                  On
                  {/* <DatePicker
                    className="w-100 select_app_date"
                    onChange={(e) => {
                      handlerepDate(e);
                    }}
                    defaultValue={moment(
                      onDatefetch === "" ? start : onDatefetch,
                      dateFormat
                    )}
                    format={dateFormat}

                    // defaultValue={new Date().toLocaleDateString()}
                    // placeholder="Until date"
                    // id="new-app-untildate"
                  /> */}
                </Radio>
                <CustomDatePicker
                  id="CustomDatePicker"
                  width="120px"
                  height="36px"
                  backgroundColour="#f7f7f7 !important"
                  border="1px solid rgba(34,36,38,.15)"
                  placeholder="MM-DD-YYYY"
                  value={onDate === "" ? aftDate : onDate}
                  onChange={(e) => {
                    let d = moment(
                      e.target.value,
                      "MM-DD-YYYY",
                      true
                    ).isValid();
                    setOnDate(e.target.value);

                    if (e.target.value === "") {
                      setValidDate1("");
                    } else if (d) {
                      let check = false;
                      check = aptdate
                        ? moment(e.target.value).isBefore(aptdate)
                          ? true
                          : false
                        : false;
                      if (check) {
                        if (onDate !== "") {
                          setOnDate(moment(onDate).format("MM-DD-YYYY"));
                        }
                      } else {
                        setValidDate1(e.target.value);
                        setdateselect2new("On");
                        setcustChk(2);
                      }
                    }
                  }}
                  onClick={(e) => handlepicker1(e)}
                  onKeyPress={(event) => {
                    if (
                      !/[0-9]/.test(event.key) ||
                      event.target.value.length > 9
                    ) {
                      event.preventDefault();
                    }
                  }}
                  onKeyDown={(e) => keyDownExpiry(e)}
                  onResultSelect={dateSelect1} // state updation for picking calendar dates
                  showPicker={showpicker1}
                  validDate={onDate} // value in date object for calendar manipulation
                  closePicker={closePicker1}
                  min={aptdate}
                  max={maxDate}
                />
              </Div>
              <Div margin="11px 0 0 0" className="rep-date2">
                <Radio className="radio_appt_btn2 sty-c-2e2e2e" value={3}>
                  After
                  <Div className="right" margin="0 0 0 52px">
                    <MdOutlineArrowDropUp className="up_arrow_custom" />
                    <MdOutlineArrowDropDown className="down_arrow_custom" />
                    <InputNumber
                      min={1}
                      max={10}
                      className="w-100 select_app_date1 occ_input1"
                      id="arrow-newApptadjust1"
                      value={occurence}
                      onChange={(val) => {
                        setcustChk(3);
                        setOccurence(val);
                      }}
                      onKeyPress={(event) => {
                        if (
                          !/[0-9]/.test(event.key) ||
                          event.target.value.length > 9
                        ) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <span className="occurence_p">Occurence</span>
                  </Div>
                </Radio>
              </Div>
            </Radio.Group>
            {custchkerr && (
              <Form.Text
                id="new-app-time-codetext appt-warn-time"
                className="colour-red m-t-5 m-b-3"
              >
                Please Select valid End Time
              </Form.Text>
            )}
          </Div>
        </Modal.Body>
        <Modal.Footer className="client-app new_appt_btn_alt">
          <Button
            className="close_active close_active1 "
            variant="light"
            onClick={handleCloserepApp}
          >
            Cancel
          </Button>
          <Button
            className="save_active save-btn"
            variant="primary"
            type="submit"
            // onClick={buttonClickActions}
            onClick={handleSaverepApp}
            disabled={btnDis}
            margin="0 9px 0 0"
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {upgrade && (
        <UpgradeRequired
          title="Enable Remote Consultation"
          description="Consult patients through video visit remotely conserving resources and improving the care."
          boxContent={
            <span>
              Available in
              <span className="upgradFont"> Premium</span> plan
            </span>
          }
          handlecloseUpgrade={handlecloseUpgrade}
        />
      )}
    </>
  );
};
export default memo(NewAppointment);
