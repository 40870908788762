import { takeLatest } from "redux-saga/effects";
import {
  GetPatientSearchData,
  GetUserSearchData,
  GetBillingFacility,
} from "../../StateManagement/Reducers/ScheduleState";
import {
  PatientSearchData,
  UserSearchData,
  BillingFacilityWorker,
} from "./ScheduleSagaWorkers";

export function* PatientSearchDataSaga() {
  yield takeLatest(GetPatientSearchData.type, PatientSearchData);
}
export function* UserSearchDataSaga() {
  yield takeLatest(GetUserSearchData.type, UserSearchData);
}

export function* BillingFacilitySaga() {
  yield takeLatest(GetBillingFacility.type, BillingFacilityWorker);
}
