import React from "react";
import { SubNavItem, ListItem, UL } from "./styles";
import { useParams } from "react-router-dom";
const SubSidebar = ({ onMouseEnter, onMouseLeave }) => {
  let params = useParams();
  let ch = 1;

  const menuItems = [
    {
      label: "Patient Dashboard",
      path: `patientdashboard/${params.pid}`,
      top: true,
    },
    { label: "Demographics", path: `demographics/${params.pid}` },
    { label: "Appointment", path: `appointment/${params.pid}` },
    { label: "Bill and Insurance", path: `billandinsurance/${params.pid}` },
    { label: "Documents", path: `documents/${params.pid}` },
    { label: "Problems", path: `problems/${params.pid}` },
    { label: "Medication", path: `medication/${params.pid}` },
    { label: "Lab Order", path: `procedureorder/${params.pid}` },
    { label: "E-Prescriptions", path: `senderx/${params.pid}/${ch}` },
    { label: "Client Portal Access", path: `clientportalaccess/${params.pid}` },
  ];
  return (
    <UL
      backgroundColor="white"
      position="fixed"
      left="235px"
      top="0"
      listStyleType="none"
      padding="0"
      width="236px"
      marginTop="48px"
      borderRight="1px solid rgba(46, 46, 46, 0.25)"
      height="100vh"
      zIndex="10001"
      textAlign="left"
      paddingLeft="21px"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      overflow="auto"
    >
      {menuItems.map((item, index) => (
        <ListItem
          key={index}
          width="100%"
          marginTop={item.top ? "28px" : "18px"}
        >
          <SubNavItem
            to={{
              pathname: `/patient/${item.path}`,
            }}
            exact
          >
            {item.label}
          </SubNavItem>
        </ListItem>
      ))}
    </UL>
  );
};

export default SubSidebar;
